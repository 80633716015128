import axios from "../../utils/axios";

class Plan {
	static async get(form) {
		return new Promise((resolve, reject) => {
			axios.post("admin/plans", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async getPayments(form) {
		return new Promise((resolve, reject) => {
			axios.post("admin/plans/payments", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async changePlan(form) {
		return new Promise((resolve, reject) => {
			axios.post("admin/plans/change", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async getDetails(form) {
		return new Promise((resolve, reject) => {
			axios.post("admin/plans/details", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}
}

export default Plan;