import React, { createRef } from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import { Globals, ENV, Constants, SocketEvents } from "../../utils";
import { User as UserService } from "../../services";
import BusinessIcon from '../../assets/icons/my-business.png';
import ProfileIcon from '../../assets/icons/my-profile.png';
import PictureIcon from '../../assets/icons/image.png';
import socket from '../../utils/socket';
import { Share, InputFormat } from "../../components";
import TrashIcon from '../../assets/icons/trash.png';
import GearIcon from '../../assets/icons/gear.png';
import BagIcon from '../../assets/icons/bag.png';
import BtnEdit from '../../assets/icons/btn-edit.png';
import TabBottom from '../../assets/icons/tab-bottom.png';
import PlanImage from '../../assets/img/plan-image.png';
import PublicHeader from '../../assets/icons/public-header.png';
import PublicImage from '../../assets/icons/public-image.png';
import PublicFooter from '../../assets/icons/public-footer.png';
import AddIcon from '../../assets/icons/add2.png';
import CalendarIcon from '../../assets/icons/calendar.png';
import ApiGuide from '../../screens/docu/api_guide';
import PrinterType from '../../screens/printer-type/printer-type';
import moment from 'moment';

const SHOW_PRICES = {
	YES: 1,
	NO: 0
}

const ACCEPT_ORDER_BEFORE = {
	YES: 1,
	NO: 0
}

const SHOW_ORDER_TOTAL = {
	YES: 1,
	NO: 0
}

const PUBLIC_TABS = {
	HEADER: 1,
	IMAGE: 2,
	FOOTER: 3
}

const TABS = {
	ACCOUNT: 1,
	BUSINESS: 2,
	GENERAL_CONFIGS: 3,
	SHOP: 4
}

const SETTINGS_TABS = {
	GENERAL: 1,
	API: 2,
	CUSTOM: 3
}

const TABS_CUSTOM = {
	ACCOUNTS: 1,
	SELL: 2,
	COMMISSIONS: 3
}

class MyProfileAccount extends React.Component {

	state = {
		// tab: this.props.user.level_id === Constants.ROLE_ADMIN ? TABS.BUSINESS : TABS.ACCOUNT,
		tab: TABS.ACCOUNT,
		form: {
			logo: {},
			logoUrl: '',
			password: '',
			confirmPassword: '',
			name: '',
			email: '',
			document: '',
			phone: '',
			address: '',
			show_prices: SHOW_PRICES.YES,
			url: ENV.BaseStore,
			formattedUrl: '',
			sell_note: '',
			facebook: '',
			instagram: '',
			twitter: '',
			header_bg: '#C70FB1',
			footer_bg: '#333333',
			banner: '',
			bannerUrl: '',
			accept_order_before: ACCEPT_ORDER_BEFORE.NO,
			percentage: '',
			iva_id: null,
			referral_link: '',
			formattedReferralLink: '',
			formattedReferralLinkWithoutRegister: '',
			show_order_total: '',
		},
		currentURL: ENV.BaseStore,
		store_session: `${this.props.user.id}-${Date.now()}`,
		photo_error: false,
		public_tab: PUBLIC_TABS.HEADER,
		plan_name: '',
		plan_expiration: undefined,
		plan_free: false,
		tab_settings: SETTINGS_TABS.GENERAL,
		methods: [],
		tab_custom: TABS_CUSTOM.SELL
	};

	constructor(props) {
		super(props);

		this.printerTypeComponent = React.createRef();
	}

	componentDidMount() {
		this.assignForm();
		this.load();
	}

	debounce = createRef();

	load = async () => {
		Globals.setLoading();

		try {
			const res = await UserService.getAdminProfile({
				user_id: this.props.user.id
			});

			if (!res.user) throw new Error('Ocurrió un error inesperado');

			await this.setState({
				form: {
					...this.state.form,
					show_prices: res.public_store?.show_prices,
					url: res.public_store?.url || '',
					facebook: res.user.facebook || '',
					instagram: res.user.instagram || '',
					twitter: res.user.twitter || '',
					header_bg: res.public_store?.header_bg || '#C70FB1',
					footer_bg: res.public_store?.footer_bg || '#333333',
					banner: res.public_store?.banner || '',
					bannerUrl: res.public_store?.banner ? Globals.fromPhotos(res.public_store.banner) : '',
					accept_order_before: res.user.accept_order_before,
					percentage: res.iva?.percentage || '',
					iva_id: res.iva?.id || '',
					referral_link: res.user.referral_link || '',
					show_order_total: res.user.show_order_total,
				},
				currentURL: ENV.BaseStore + (res.public_store?.url || ''),
				plan_name: res.plan_name || '',
				plan_expiration: res.plan_expiration || undefined,
				plan_free: res.plan_free || false,
				plan_user: res.plan_user || null,
				methods: res.methods || []
			});
			await this.formatUrl();

		} catch (error) {
			console.log(error);
			Globals.showError('Ocurrió un error inesperado');
		}

		Globals.quitLoading();
	}

	assignForm = async () => {
		await this.setState({
			form: {
				...this.state.form,
				logoUrl: this.props.user.logo ? Globals.fromPhotos(this.props.user.logo) : '',
				name: this.props.user?.name || '',
				email: this.props.user?.email || '',
				document: this.props.user?.document || '',
				phone: this.props.user?.phone || '',
				address: this.props.user?.address || '',
				sell_note: this.props.user?.sell_note || '',
				facebook: this.props.user?.facebook || '',
				instagram: this.props.user?.instagram || '',
				twitter: this.props.user?.twitter || '',
			}
		});
	}

	onChangeEvent = async (event) => {
		const target = event.target.name;
		const value = event.target.value;

		await this.setState(state => ({
			form: { ...state.form, [target]: value }
		}));

		if (!['header_bg','footer_bg'].includes(target)) return;

		if (this.debounce.current) clearTimeout(this.debounce.current);
		this.debounce.current = setTimeout(async () => {
			socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.COLORS, {
				header_bg: this.state.form.header_bg,
				footer_bg: this.state.form.footer_bg,
				store_session: this.state.store_session,
			});
		}, 500);
	}

	onChange = async (value, target) => {
		await this.setState(state => ({
			form: {
				...state.form,
				[target]: !!value?.target ? value?.target?.value : value,
			}
		}));
	}

	formatUrl = async () => {
		const currentOrigin = `${window.location.origin}${window.location.pathname}`; // Obtiene el origen y la ruta actual
	
		await this.setState(state => ({
			form: {
				...state.form,
				formattedUrl: ENV.BaseStore + state.form.url,
				formattedReferralLink: `${currentOrigin}#/register${state.form.referral_link}`, // Enlace de referido

				/*
				// Remueve "-" seguido del ID
				formattedReferralLinkWithoutRegister: `${currentOrigin}#/${
					state.form.referral_link.replace(/-\d+$/, '')  // <- Cambio clave aquí
				}`,
				*/
				formattedReferralLinkWithoutRegister: `${currentOrigin}#/${
					state.form.referral_link.replace(/^\d+/, '') // <- Cambio clave aquí
				}`				
			}
		}));
	}
	

	uploadPhoto = async (e) => {
    	const currentFile = URL.createObjectURL(e.target.files[0]);
		await this.onChange(e.target.files[0], 'logo');
		await this.onChange(currentFile, 'logoUrl');
		await this.setState({ photo_error: false });
	}

	setTab = (tab) => {
		this.setState({ tab });
	}

	onSubmit = async () => {
		if (!this.isValidForm()) return;
		Globals.setLoading();

		try {
			const res = await UserService.editAdminProfile({
				web: true,
				user_id: this.props.user.id,
				...this.state.form,
				hasFile: true,
				methods: JSON.stringify(this.state.methods.filter(i => i.active).map(i => i.id))
			});

			if (!res.user) throw new Error('Ocurrió un error inesperado');

			this.props.dispatch({
				type: 'SET_USER',
				payload: res.user,
			});

			this.setState({
				form: { ...this.state.form, password: '', confirmPassword: '' },
			});

			Globals.showSuccess('Se han guardado los cambios');

		} catch (error) {
			console.log(error);
			Globals.showError(error?.error || 'Ocurrió un error inesperado');
		}

		Globals.quitLoading();

		this.printerTypeComponent.current?.submit();

		this.load();
	}

	isValidForm = () => {
		const onError = (message) => {
			Globals.showError(message);
			return false;
		}

		const { form } = this.state;

		if (!form.email){
			return onError('El correo es obligatorio');
		}

		if (!!form.password || !!form.confirmPassword) {
			if (!form.password)
				return onError('La contraseña es obligatoria');
		
			if (form.password !== form.confirmPassword)
				return onError('Las contraseñas no coinciden');
		}

		if(this.props.user.level_id === Constants.ROLE_ADMIN){
			// if (!form.url){
			// 	return onError('La URL es obligatoria');
			// }	
	
			if (form.url.includes(' ')){
				return onError('La URL no puede tener espacios en blanco');
			}
		}

		return true;
	}

	shareURL = () => {
		if (!navigator.share)
			return Globals.showError('Tu navegador no permite esta función');

		try {
			navigator.share({
				title: 'Catálogo Virtual',
				url: this.state.form.formattedUrl,
			});

		} catch (error) {
			console.log(error)
		}
	}

	onUploadBanner = async (e) => {
		if (!e.target.files[0]) return;
		if (!['image/jpeg','image/png','image/gif'].includes(e.target.files[0].type))
			return Globals.showError('La imagen debe ser formato JPG o PNG');
		if (e.target.files[0].size > 500000)
			return Globals.showError('La imagen no puede pesar más de 500KB');

    const currentFile = URL.createObjectURL(e.target.files[0]);
		const file = new FileReader();
		file.readAsDataURL(e.target.files[0]);
		file.onload = () => {
			socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, {
				banner: file.result,
				store_session: this.state.store_session,
			});
		}
		await this.onChange(e.target.files[0], 'banner');
		await this.onChange(currentFile, 'bannerUrl');
	}

	onRemoveBanner = async () => {
		await this.onChange('', 'banner');
		await this.onChange('', 'bannerUrl');
		document.getElementById('banner-image').value = null;
		socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, {
			banner: null,
			store_session: this.state.store_session,
		});
	}

	setPublicTab = (tab) => {
		this.setState({
			public_tab: tab
		});
	}

	setSettingsTab = (tab) => {
		this.setState({
			tab_settings: tab
		});	
	}

	setTabCustom = (tab) => {
		this.setState({
			tab_custom: tab
		});
	}

	changeIVA = e => {
	    // Limitar los números enteros a 8
	    let nums = String(e.value).split('.', 2);
	    let value = nums[0].substring(0, 8);
	    value = `${value}${nums[1] ? `.${nums[1]}` : ''}`;

	    this.setState({
	      form: {
	        ...this.state.form,
	        percentage: value
	      }
	    });
	}

	setMethodActive = (index) => {
		let methods = [...this.state.methods];
		methods[index].active = !methods[index].active;
		this.setState({
			methods
		});
	}

	toViewPayments = () => {

	}

	toChangePlay = () => {

	}

	toPay = () => {
		this.props.history.push("/profile-business/payment");
	}

	render() {
		const { history } = this.props;
		const { form, tab, tab_custom } = this.state;
		const photo = this.state.photo_error ? PictureIcon : (form?.logoUrl || PictureIcon);
		const banner = form?.bannerUrl || PictureIcon;

		console.log(this.state.pla)
		return (
			<Menu history={history}>
				<div id="my-profile-account">
					{/* <RenewalModal
						isOpen={this.state.isRenewalModalOpen}
						onClose={this.toggleRenewalModal}
						plan_user={this.state.plan_user}
						user={this.props.user}
					/> */}

					<nav>
						<button
							className={tab === TABS.ACCOUNT ? 'active': ''}
							onClick={() => this.setTab(TABS.ACCOUNT)}
						>
							<img src={ProfileIcon} alt="Mi cuenta" />
							<span>Mi Cuenta</span>
						</button>
						{this.props.user.level_id === Constants.ROLE_ADMIN && (
							<>
								<button
									className={tab === TABS.BUSINESS ? 'active': ''}
									onClick={() => this.setTab(TABS.BUSINESS)}
								>
									<img src={BusinessIcon} alt="Mi negocio" />
									<span>Mi Negocio</span>
								</button>
								<button
									className={tab === TABS.SHOP ? 'active': ''}
									onClick={() => this.setTab(TABS.SHOP)}
								>
									<img src={BagIcon} alt="Tienda Web" />
									<span>Tienda Web</span>
								</button>
								<button
									className={tab === TABS.GENERAL_CONFIGS ? 'active' : ''}
									onClick={() => this.setTab(TABS.GENERAL_CONFIGS)}
								>
									<img src={GearIcon} alt="Configuración" />
									<span>Configuración</span>
								</button>
							</>
						)}
					</nav>

					{tab === TABS.ACCOUNT && (
						<main className="account-tab">
							<section>
								<div className="control-group">
									<div className="control w-full">
										<label>Correo</label>
										<input
											name="email"
											value={form.email}
											onChange={this.onChangeEvent}
										/>
									</div>
									<p className="control w-full title-section">Cambiar contraseña</p>
									<div className="row">
										<div className="col-md-6">
											<div className="control">
												<label>Nueva contraseña</label>
												<input
													type="password"
													placeholder="******"
													name="password"
													autoComplete="cc-number"
													value={form.password}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="control">
												<label>Repetir contraseña</label>
												<input
													type="password"
													placeholder="******"
													name="confirmPassword"
													autoComplete="cc-number"
													value={form.confirmPassword}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
									</div>
									<main className="business-tab">
										<section className="catalogue">
											<div className="container-white">
												<section>
													<p className="label">Link de referido</p>
													<div className="url-control">
														<div className="prefix">
															<span>URL</span>
														</div>
														<input
															name="referral_link"
															//value={form.referral_link}
															//value={form.referral_link.replace(/-\d+$/, '')} // Muestra solo el texto sin "-96"
															value={form.referral_link.replace(/^\d+/, '')}
															autoComplete="none"
															onChange={async (event) => {
																await this.onChangeEvent(event);
																await this.formatUrl();
															}}
														/>
														<button onClick={() => Globals.copyToClipboard(this.state.form.formattedReferralLink)} />
													</div>
													{/* <p className="link">{form.formattedReferralLinkWithoutRegister}</p> */}
													<p className="link">{form.formattedReferralLink}</p>
												</section>
											</div>
										</section>
										
									</main>
									
								</div>
							</section>

							<section>
								{
									this.props.user.level_id === Constants.ROLE_ADMIN && (
										<div className="container-plan">
											<div>
												{this.state.plan_free ? (
													<h4>Prueba Gratuita</h4>
												) : (
													<h4>{ this.state.plan_name } ${ Globals.formatMiles(this.state.plan_user?.amount) }</h4>
												)}
												<p>
													<img src={CalendarIcon} alt="Próximo pago" />
													<span>Próximo pago: </span>
													<span>{ moment(this.state.plan_expiration).format('DD/MM/YYYY') }</span>
												</p>

												{/* <button className="payments-btn" onClick={this.toViewPayments}>
													Ver Pagos
												</button> */}

												<div className="plan-actions">
													{/* <button className="change-plan-btn" onClick={this.toChangePlay}>
														Cambiar Plan
													</button> */}
													{/* <button className="change-plan-btn" onClick={this.toggleRenewalModal}>
														Renovar Plan
													</button> */}
													<button className="pay-btn" onClick={this.toPay}>
														Pagar
													</button>

													<div style={{ flex: 1 }} />
												</div>
											</div>

											<img className="plan-asset" src={ PlanImage } alt="Plan" />
									</div>
									)
								}
							</section>

							<section>
								<div className="actions">
									<button className="save-btn primary" onClick={this.onSubmit}>
										<span>GUARDAR</span>
									</button>
								</div>
							</section>
						</main>
					)}

					{tab === TABS.BUSINESS && (
						<main className="business-tab">
							<section className="profile">
								<div className="avatar-wrapper">
									<div className="filename">Foto de logo</div>
									<p className="filename-subtitle">La imagen debe tener un mínimo de 800px por 800px</p>
									<label className="avatar" htmlFor="upload-photo">
										<img
											src={photo}
											className={(this.state.photo_error || photo === PictureIcon) ? 'placeholder' : ''}
											onError={({ currentTarget }) => {
												this.setState({
													photo_error: true
												});
											}}
											alt="Foto de perfil"
										/>
										<input
											type="file"
											id="upload-photo"
											onChange={this.uploadPhoto}
										/>
										<img src={ BtnEdit } className="btn-edit" />
									</label>
								</div>
								<div className="control-group">
									<div className="row">
										<div className="col-md-12">
											<div className="control">
												<label>Nombre</label>
												<input
													name="name"
													value={form.name}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="control">
												<label>RIF</label>
												<input
													name="document"
													value={form.document}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="control">
												<label>Teléfono</label>
												<input
													name="phone"
													value={form.phone}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="control">
												<label>Dirección fiscal</label>
												<textarea
													row={ 4 }
													name="address"
													value={form.address}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="control w-full">
												<label>Políticas notas de venta</label>
												<textarea
													row={ 4 }
													name="sell_note"
													value={form.sell_note}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
									</div>	

									<button className="save-btn primary" onClick={this.onSubmit} style={{ margin: '0 auto' }}>
										<span>GUARDAR</span>
									</button>						
								</div>
							</section>
						</main>
					)}

					{tab === TABS.SHOP && (
						<main className="business-tab">
							<section className="catalogue">
								{/*<div className="section-big">
									<p>TIENDA PÚBLICA WEB (Catálogo virtual)</p>
								</div>*/}
								<p className="section-label">Dirección pública (como los clientes entrarán a tu página)</p>

								<div className="row">
									<div className="col-md-6">
										<div className="container-white">
											<section>
												<p className="label">Nombre para tu dirección pública</p>
												<div className="url-control">
													<div className="prefix">
														<span>URL</span>
													</div>
													<input
														name="url"
														value={form.url}
														autoComplete="none"
														onChange={async (event) => {
															await this.onChangeEvent(event);
															await this.formatUrl();
														}}
													/>
													<button onClick={() => Globals.copyToClipboard(this.state.form.formattedUrl)} />
												</div>
												<p className="link">{ form.formattedUrl }</p>
											</section>
										</div>
									</div>
									<div className="col-md-6">
										<div className="container-white">
											<div className="actions">
												<p>Comparte tu enlace para que puedan ver tu catálogo</p>
												<Share url={form.formattedUrl} />
											</div>
										</div>

										<div className="container-white">
											<div className="show-prices">
												<p>¿Deseas que la Web tenga público los precios?</p>
												<div className="checkbox-items">
													<div
														className="checkbox-item"
														onClick={() => this.onChange(SHOW_PRICES.YES, 'show_prices')}
													>
														<input id="show-prices-yes"
															type="checkbox"
															checked={form.show_prices}
															onChange={() => this.onChange(SHOW_PRICES.YES, 'show_prices')}
														/>
														<label htmlFor="show-prices-yes">Sí</label>
													</div>

													<div
														className="checkbox-item"
														onClick={() => this.onChange(SHOW_PRICES.NO, 'show_prices')}
													>
														<input id="show-prices-no"
															type="checkbox"
															checked={!form.show_prices}
															onChange={() => this.onChange(SHOW_PRICES.NO, 'show_prices')}
														/>
														<label htmlFor="show-prices-no">No</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<section className="social-container">
									<p className="section-label">Redes Sociales</p>
									<div className="row">
										<div className="col-md-4">
											<label>Facebook</label>
											<input
												placeholder="URL"
												name="facebook"
												value={form.facebook}
												onChange={this.onChangeEvent}
											/>
										</div>
										<div className="col-md-4">
											<label>Instagram</label>
											<input
												placeholder="URL"
												name="instagram"
												value={form.instagram}
												onChange={this.onChangeEvent}
											/>
										</div>
										<div className="col-md-4">
											<label>X</label>
											<input
												placeholder="URL"
												name="twitter"
												value={form.twitter}
												onChange={this.onChangeEvent}
											/>
										</div>
									</div>
								</section>

								<p className="section-label">Apariencia del sitio</p>
								<section>
									<div className="appearance row">
										<div className="col-md-5">
											<div className={ `container-public-tab ${ this.state.public_tab == PUBLIC_TABS.HEADER ? 'active' : '' }` } onClick={ () => this.setPublicTab(PUBLIC_TABS.HEADER) }>
												<img src={ TabBottom } className="tab-arrow" />
												<img src={ PublicHeader } className="image" />
												<p>Barra del menú</p>
											</div>
											{
												this.state.public_tab == PUBLIC_TABS.HEADER && (
													<div className="public-tab-padding">
														<div className="control">
															<label>Color</label>
															<input
																type="color"
																name="header_bg"
																value={form.header_bg}
																onChange={this.onChangeEvent}
															/>
														</div>
													</div>
												)
											}
											<div className={ `container-public-tab ${ this.state.public_tab == PUBLIC_TABS.IMAGE ? 'active' : '' }` } onClick={ () => this.setPublicTab(PUBLIC_TABS.IMAGE) }>
												<img src={ TabBottom } className="tab-arrow" />
												<img src={ PublicImage } className="image" />
												<p>Banner imagen</p>
											</div>
											{
												this.state.public_tab == PUBLIC_TABS.IMAGE && (
													<div className="public-tab-padding">
														<label style={{ fontSize: 12 }}>(medida sugerida 1900px por 400px, máximo 500KB)</label>
														<div className="banner-upload">
															<label className="banner-input">
																<div className="banner-preview">
																	<img
																		src={banner}
																		className={banner === PictureIcon ? 'placeholder' : ''}
																		onError={({ currentTarget }) => {
																			currentTarget.onerror = null;
																			currentTarget.classList.add('placeholder');
																			currentTarget.src = PictureIcon;
																		}}
																		alt="Banner"
																	/>
																</div>
																<input
																	type="file"
																	accept="image/*"
																	name="banner"
																	id="banner-image"
																	onChange={this.onUploadBanner}
																/>
															</label>
															<img
																src={TrashIcon}
																className="banner-upload__delete"
																alt="Eliminar"
																title="Eliminar banner"
																onClick={this.onRemoveBanner}
															/>
														</div>
													</div>
												)
											}
											<div className={ `container-public-tab ${ this.state.public_tab == PUBLIC_TABS.FOOTER ? 'active' : '' }` } onClick={ () => this.setPublicTab(PUBLIC_TABS.FOOTER) }>
												<img src={ TabBottom } className="tab-arrow" />
												<img src={ PublicFooter } className="image" />
												<p>Footer</p>
											</div>
											{
												this.state.public_tab == PUBLIC_TABS.FOOTER && (
													<div className="public-tab-padding">
														<div className="control">
															<label>Color</label>
															<input
																type="color"
																name="footer_bg"
																value={form.footer_bg}
																onChange={this.onChangeEvent}
															/>
														</div>
													</div>
												)
											}
										</div>
										<div className="col-md-7">
											<div className="preview">
												{this.state.currentURL !== ENV.BaseStore ? (
													<StorePreview currentURL={this.state.currentURL} store_session={this.state.store_session} />
												) : (
													<p>No tiene URL de la tienda para mostrar</p>
												)}
											</div>
										</div>
									</div>
									<button className="save-btn primary" onClick={this.onSubmit}>
										<span>GUARDAR</span>
									</button>
								</section>
							</section>
						</main>
					)}

					{tab === TABS.GENERAL_CONFIGS && (
						<main className="business-tab">
							<section className="catalogue">
								<div className="container-tab-settings">
									<div className={ `tab-settings ${ this.state.tab_settings == SETTINGS_TABS.GENERAL ? 'active' : '' }` } onClick={ () => this.setSettingsTab(SETTINGS_TABS.GENERAL) }>
										<p>Generales</p>
									</div>
									<div className={ `tab-settings ${ this.state.tab_settings == SETTINGS_TABS.API ? 'active' : '' }` } onClick={ () => this.setSettingsTab(SETTINGS_TABS.API) }>
										<p>Integración API</p>
									</div>
									<div className={ `tab-settings ${ this.state.tab_settings == SETTINGS_TABS.CUSTOM ? 'active' : '' }` } onClick={ () => this.setSettingsTab(SETTINGS_TABS.CUSTOM) }>
										<p>Personalizadas</p>
									</div>
								</div>
							</section>
						</main>
					)}

					{
						tab === TABS.GENERAL_CONFIGS && this.state.tab_settings == SETTINGS_TABS.GENERAL && (
							<p className="section-label" style={{ textAlign: 'center' }}>Impresora por defecto en notas de entrega</p>
						)
					}
					<PrinterType ref={ this.printerTypeComponent } visible={ tab === TABS.GENERAL_CONFIGS && this.state.tab_settings == SETTINGS_TABS.GENERAL } />

					{tab === TABS.GENERAL_CONFIGS && (
						<main className="business-tab">
							<section className="catalogue">
								<section>
									{
										this.state.tab_settings == SETTINGS_TABS.GENERAL && (
											<>
												<section className="social-container">
													<p className="section-label" style={{ textAlign: 'center' }}>IVA %</p>

													<div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
														<div className="iva-container">
															<p>Porcentaje</p>
															<InputFormat
												                name="percentage"
												                thousandSeparator={true}
												                allowNegative={false}
												                isNumericString={true}
												                decimalScale="2"
												                value={this.state.form.percentage}
												                onValueChange={this.changeIVA}
												            />
												        </div>
												    </div>
												</section>

												<p className="section-label">Métodos de Pago</p>

												<div className="container-methods">
													<div className="row">
														{
															this.state.methods.map((item,index) => {
																return (
																	<div className="col-md-4">
																		<div className="item-check-method" onClick={ () => this.setMethodActive(index) }>
																			<div className={ `check ${ item.active ? 'active' : '' }` }></div>
																			<p>{ item.name }</p>
																		</div>
																	</div>
																)
															})
														}
													</div>
												</div>
											</>
										)
									}

									{
										this.state.tab_settings == SETTINGS_TABS.API && (
											<>
												<ApiGuide />
											</>
										)
									}

									{
										this.state.tab_settings == SETTINGS_TABS.CUSTOM && (
											<>

												{/*<div className="container-tabs-custom">
													<div className="container-buttons">
														<button
															className={tab_custom === TABS_CUSTOM.ACCOUNTS ? 'active': ''}
															onClick={() => this.setTabCustom(TABS_CUSTOM.ACCOUNTS)}
														>
															Cuentas por cobrar/descuentos
														</button>
														<button
															className={tab_custom === TABS_CUSTOM.SELL ? 'active': ''}
															onClick={() => this.setTabCustom(TABS_CUSTOM.SELL)}
														>
															Metas de venta
														</button>
														<button
															className={tab_custom === TABS_CUSTOM.COMMISSIONS ? 'active': ''}
															onClick={() => this.setTabCustom(TABS_CUSTOM.COMMISSIONS)}
														>
															Comisiones
														</button>
													</div>
												</div>*/}

												{
													tab_custom == TABS_CUSTOM.ACCOUNTS && (
														<div className="custom-container">
															<h3 className="custom-title">Cuentas por cobrar</h3>
															<div className="item-check">
																<div className="check"></div>
																<p>Autorizar a los usuarios señalados, acceso a cuentas por cobrar para editar campos determinados en pedidos procesados:</p>
															</div>
															<div className="custom-white row">
																<div className="col-md-6">
																	<h4>Usuarios</h4>
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Moderadores</p>
																	</div>
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Vendedores</p>
																	</div>
																</div>
																<div className="col-md-6">
																	<h4>Campos de pedidos a editar</h4>
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Días de crédito</p>
																	</div>
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Porcentaje de descuento general</p>
																	</div>
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Porcentaje de descuento por volúmenes de productos</p>
																	</div>
																</div>
															</div>
															<div className="separator"></div>
															<h3 className="custom-title">Descuentos a clientes</h3>
															<div className="row">
																<div className="col-md-8">
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Aplicar descuentos por pronto pago a clientes que acepten plazo de pago:</p>
																	</div>
																</div>
																<div className="col-md-4">
																	<button className="btn-range">
																		<div className="container-add">
																			<img src={ AddIcon } />
																		</div>
																		<p>Agregar rango</p>
																	</button>
																</div>
															</div>
															<div className="item-check margin-left">
																<div className="check"></div>
																<p>
																	Rango 1: De
																	<div className="form-group">
																		<label>Días</label>
																		<input
															                onChange={ () => {} } />
																	</div>
																	a
																	<div className="form-group">
																		<label>Días</label>
																		<input
															                onChange={ () => {} } />
																	</div>
																	otorgan descuento de
																	<div className="form-group">
																		<label>%</label>
																		<input
															                onChange={ () => {} } />
																	</div>
																	en monto de pedido
																</p>
															</div>
														</div>
													)
												}

												{
													tab_custom == TABS_CUSTOM.SELL && (
														<div className="custom-container">
															<h3 className="custom-title">Inventario</h3>
															<div className="show-prices">
																<p>¿El administrador debe aceptar los pedidos para descontar del inventario?</p>
																<div className="checkbox-items">
																	<div
																		className="checkbox-item"
																		onClick={() => this.onChange(ACCEPT_ORDER_BEFORE.YES, 'accept_order_before')}
																	>
																		<input id="show-prices-yes"
																			type="checkbox"
																			checked={form.accept_order_before}
																			onChange={() => this.onChange(ACCEPT_ORDER_BEFORE.YES, 'accept_order_before')}
																		/>
																		<label htmlFor="show-prices-yes">Sí</label>
																	</div>

																	<div
																		className="checkbox-item"
																		onClick={() => this.onChange(ACCEPT_ORDER_BEFORE.NO, 'accept_order_before')}
																	>
																		<input id="show-prices-no"
																			type="checkbox"
																			checked={!form.accept_order_before}
																			onChange={() => this.onChange(ACCEPT_ORDER_BEFORE.NO, 'accept_order_before')}
																		/>
																		<label htmlFor="show-prices-no">No</label>
																	</div>
																</div>
															</div>

															{this.props.user.level_id === Constants.ROLE_ADMIN && (
																<>
																	<h3 className="custom-title">Despachos</h3>
																	<div className="show-prices">
																		<p>Mostrar monto total en despachos</p>
																		<div className="checkbox-items">
																			<div
																				className="checkbox-item"
																				onClick={() => this.onChange(SHOW_ORDER_TOTAL.YES, 'show_order_total')}
																			>
																				<input
																					id="show-prices-yes"
																					type="checkbox"
																					checked={form.show_order_total}
																					onChange={() => this.onChange(SHOW_ORDER_TOTAL.YES, 'show_order_total')}
																				/>
																				<label htmlFor="show-prices-yes">Sí</label>
																			</div>

																			<div
																				className="checkbox-item"
																				onClick={() => this.onChange(SHOW_ORDER_TOTAL.NO, 'show_order_total')}
																			>
																				<input
																					id="show-prices-no"
																					type="checkbox"
																					checked={!form.show_order_total}
																					onChange={() => this.onChange(SHOW_ORDER_TOTAL.NO, 'show_order_total')}
																				/>
																				<label htmlFor="show-prices-no">No</label>
																			</div>
																		</div>
																	</div>
																</>
															)}
															
															<div style={{ marginTop: '20px' }}></div>
														</div>
													)
												}

												{
													tab_custom == TABS_CUSTOM.COMMISSIONS && (
														<div className="custom-container">
															<h3 className="custom-title">Comisiones de Vendedores</h3>
															<div className="row row-check">
																<div className="col-md-8">
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Aplicar comisiones para vendedores según los plazos de pago a pedidos:</p>
																	</div>
																</div>
																<div className="col-md-4">
																	<button className="btn-range">
																		<div className="container-add">
																			<img src={ AddIcon } />
																		</div>
																		<p>Agregar rango</p>
																	</button>
																</div>
															</div>
															<div className="item-check margin-left">
																<div className="check"></div>
																<p>
																	Si el período de pago es 
																	<div className="form-group">
																		<label>días</label>
																		<input
															                onChange={ () => {} } />
																	</div>
																	hasta
																	<div className="form-group">
																		<label>días</label>
																		<input
															                onChange={ () => {} } />
																	</div>
																	otorgan comisión de
																	<div className="form-group">
																		<label>%</label>
																		<input
															                onChange={ () => {} } />
																	</div>
																</p>
															</div>
															<div style={{ marginTop: '20px' }}></div>
															<div className="separator"></div>
															<h3 className="custom-title">Comisiones de Gerentes</h3>
															<div className="row row-check">
																<div className="col-md-8">
																	<div className="item-check">
																		<div className="check"></div>
																		<p>Aplicar comisiones para gerentes según los plazos de pago a pedidos:</p>
																	</div>
																</div>
																<div className="col-md-4">
																	<button className="btn-range">
																		<div className="container-add">
																			<img src={ AddIcon } />
																		</div>
																		<p>Agregar rango</p>
																	</button>
																</div>
															</div>
															<div className="item-check margin-left">
																<div className="check"></div>
																<p>
																Si el período de pago es 
																<div className="form-group">
																	<label>días</label>
																	<input
														                onChange={ () => {} } />
																</div>
													            hasta
													            <div className="form-group">
																	<label>días</label>
																	<input
														                onChange={ () => {} } />
																</div>
													            otorgan comisión de 
													            <div className="form-group">
																	<label>%</label>
																	<input
														                onChange={ () => {} } />
																</div>
													            </p>
															</div>
															<div style={{ marginTop: '20px' }}></div>
														</div>
													)
												}
											</>
										)
									}
									
									{
										this.state.tab_settings != SETTINGS_TABS.API && (
											<button className="save-btn primary" onClick={this.onSubmit}>
												<span>GUARDAR</span>
											</button>
										)
									}
								</section>

							</section>
						</main>
					)}
				</div>
			</Menu>
		);
	}
}

const StorePreview = React.memo(({ currentURL, store_session }) => (
	<iframe
		title="Vista previa"
		src={`${currentURL}${Globals.toParams({ store_session })}`}
	/>
))

export default connect(state => {
	return {
		user: state.user
	};
})(MyProfileAccount);
