import React, { Component } from 'react';
import { Button, Select } from '../../components';

import LogoWhite from '../../assets/img/logo-white.png';
import Logo from '../../assets/img/logo.png';
import LoginBG from '../../assets/img/login-bg.jpg';
import FacebookIcon from '../../assets/icons/facebook.png';
import GoogleIcon from '../../assets/icons/google.png';
import BackButtonBlue from '../../assets/icons/arrow-left-blue.png';
import { Constants, Globals, axios } from '../../utils';
import { Auth, Sidebar } from "../../services";
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

const STEP = {
  REGISTER: 1,
  PLAN: 2,
  PAYMENT: 3,
}

class Register extends Component {

  state = {
    step: STEP.REGISTER,
    submitted: false,
    form: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      country_id: '',
      terms_conditions: 0,
    },
    countries: [],
  }

  componentDidMount() {
    this.getCountries();

    const { match } = this.props;

    // Verifica si hay un código de referido en la URL
    if (match.params.referral) {
      // Guardar el código de referido en una cookie
      Cookies.set('referral', match.params.referral, { expires: 365 * 100 }); // Caduca en un año
    }
  }

  getCountries = async () => {
    try {
      const response = await Auth.getCountries('auth/countries');
      const countries = response.countries.map(country => ({ value: country.id, label: country.name }));
      await this.setState({ countries });
      this.onChange(response.countries[0].id,'country_id');

    } catch (error) {
      Globals.showError();
      console.log(error);
    }
  }

  onChange = async (value, target, e) => {
    await this.setState(state => ({
      form: { ...state.form, [target]: value }
    }));

    if (target === 'country_id') {
      document.getElementById('country_id').classList.add('filled');
    }
  }

  gotToStep = (step) => {
    this.setState({ step });
  }

  isValidForm = () => {
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    const { form } = this.state;

    if (!form.name)
      return onError('Debe indicar el nombre');

    if (!form.lastname)
      return onError('Debe indicar el apellido');

    if (!form.email)
      return onError('Debe indicar el correo');

    if (!form.phone)
      return onError('Debe indicar el teléfono');

    if (!form.password)
      return onError('Debe indicar la contraseña');

    if (form.password !== form.password_confirmation)
      return onError('Las contraseñas no coinciden');

    return true;
  }

  /*
  onSubmit = async () => {
    if (!this.isValidForm()) return;

    Globals.setLoading();

    try {
      await Auth.register(this.state.form);
      this.authenticate();

    } catch (error) {
      console.log(error);
      Globals.showError(error?.error);
    }

    Globals.quitLoading();
  }
  */
  onSubmit = async () => {
    if (!this.isValidForm()) return;

    Globals.setLoading();

    try {
      let form = { ...this.state.form }

      // Obtén la cookie 'referral'
      const referralCode = Cookies.get('referral');
      console.log('Referral Code:', referralCode);

      if (referralCode) {
        form.referral_code = referralCode
      }

      await Auth.register(form);
      this.authenticate();

    } catch (error) {
      console.log(error);
      Globals.showError(error?.error);
    }

    Globals.quitLoading();
  };

  authenticate = () => {
    const form = {
      email: this.state.form.email,
      password: this.state.form.password
    }
    Auth.authenticate(form)
      .then(({ user, token, message }) => {
        console.log(user, token, message)
        if(message !== null) Globals.showWarning(message);

        this.props.dispatch({ type: "SET_USER", payload: user });
        this.props.dispatch({ type: "SET_ADMIN", payload: Globals.setTypeRole(user) });
        localStorage.setItem("token", JSON.stringify(token));

        return user;
      })
      .then(user => {
        console.log(user)
        if (!user) return;

        Sidebar.getModuleByUser(user.id)
          .then(modules => {
            this.props.dispatch({ type: "SET_SIDEBAR", payload: modules });
            this.props.history.push('/dashboard');
          })
          .catch(error => console.log({ error: error }))
          .finally(() => this.setState({ submitted: false }));
      })
      .catch(error => console.log(error))
      .finally(() => this.setState({ submitted: false }));
  }

  registerGoogle = () => {

  }

  registerFacebook = () => {

  }

  render() {

    const { form, step } = this.state;

    return (
      <div id="register">
        <div className="left">
          <header>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>

            <button
              className="go-back"
              type="button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={BackButtonBlue} alt="Volver" />
            </button>
          </header>

          {/* <nav className="stepper">
            <div className="track">
              <div className="thumb" />
            </div>

            <Tab
              step={STEP.REGISTER}
              label="Registro"
              isActive={step === STEP.REGISTER}
              onClick={() => this.gotToStep(STEP.REGISTER)}
            />

            <Tab
              step={STEP.PLAN}
              label="Plan"
              isActive={step === STEP.PLAN}
              onClick={() => this.gotToStep(STEP.PLAN)}
            />

            <Tab
              step={STEP.PAYMENT}
              label="Método de Pago"
              isActive={step === STEP.PAYMENT}
              onClick={() => this.gotToStep(STEP.PAYMENT)}
            />
          </nav> */}

          <form className="row">
            <div className="col-12 col-lg-6">
              <MuiInput
                label="Nombre"
                value={form.name}
                onChange={e => this.onChange(e.target.value, 'name')}
              />
            </div>
            <div className="col-12 col-lg-6">
              <MuiInput
                label="Apellidos"
                value={form.lastname}
                onChange={e => this.onChange(e.target.value, 'lastname')}
              />
            </div>
            <div className="col-12 col-lg-6">
              <MuiInput
                label="Correo electrónico"
                className="mui"
                type="email"
                value={form.email}
                onChange={e => this.onChange(e.target.value, 'email')}
              />
            </div>
            <div className="col-12 col-lg-6">
              <MuiInput
                label="Teléfono"
                type="tel"
                value={form.phone}
                onChange={e => this.onChange(e.target.value, 'phone')}
              />
            </div>
            <div className="col-6">
              <Select
                id="country_id"
                color=" "
                defaultname=" "
                labelClass="material"
                className="mui"
                options={this.state.countries}
                value={String(this.state.form.country_id)}
                onChange={e => this.onChange(e.target.value, 'country_id', e)}
                caret={(
                  <>
                    <label>País</label>
                    <i
                      className="fa fa-regular fa-chevron-down"
                      style={{position: 'absolute', bottom: 16, right: 10, fontSize: 20, color: 'gray'}}
                    />
                  </>
                )}
              />
            </div>
            <div className="col-12 col-lg-6" />
            <div className="col-12 col-lg-6">
              <MuiInput
                label="Contraseña"
                type="password"
                value={form.password}
                onChange={e => this.onChange(e.target.value, 'password')}
              />
            </div>
            <div className="col-12 col-lg-6">
              <MuiInput
                label="Repetir contraseña"
                type="password"
                value={form.password_confirmation}
                onChange={e => this.onChange(e.target.value, 'password_confirmation')}
              />
            </div>
          </form>

          <footer>
            <div className="divider" />

            {/* Checkbox de términos y condiciones */}
            <div className="terms-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={form.terms_conditions === Constants.STATUS_ACTIVE ? true : false}
                  onChange={() => {
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        terms_conditions: state.form.terms_conditions === Constants.STATUS_ACTIVE
                          ? Constants.STATUS_INACTIVE
                          : Constants.STATUS_ACTIVE,
                      }
                    }));
                  }}
                />
                <span>
                  {' '}
                  <a
                    href="https://sysge24.com/terminos_condiciones_SYSGE24-2510.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="terms-link"
                  >
                    Acepto los términos y condiciones
                  </a>
                </span>
              </label>
            </div>

            <div className="row register-with">
              <div className="register-with__btn" onClick={this.registerGoogle}>
                <img src={GoogleIcon} alt="Google" />
                <span>Registrarse con Google</span>
              </div>
              <div className="register-with__btn" onClick={this.registerFacebook}>
                <img src={FacebookIcon} alt="Google" />
                <span>Registrarse con Facebook</span>
              </div>
            </div>

            <div className="text-center">
              <Button
                type="submit"
                color=" "
                className="primary"
                onClick={this.onSubmit}
                disabled={form.terms_conditions === Constants.STATUS_INACTIVE ? true : false}
              >
                <span>Comenzar</span>
              </Button>
            </div>
          </footer>
        </div>

        <div className="right">
          <div>
            <img className="logo" src={LogoWhite} alt="Logo" />
          </div>
          <img src={LoginBG} alt="Login Background" />
        </div>
      </div>
    )
  }
}

const Tab = ({ step, label, isActive, onClick }) => {
  const activeClass = isActive ? 'active' : '';

  return (
    <div className={`tab ${activeClass}`}>
      <div className="circle-wrapper">
        <div
          className="circle"
          onClick={() => onClick ? onClick() : {}}
        >
          <span>{ step }</span>
        </div>
      </div>
      <label onClick={() => onClick ? onClick() : {}}>
        { label }
      </label>
    </div>
  );
}

const MuiInput = (props) => {

  const className = props.className ? props.className : '';
  const errorClass = props.error ? props.error : '';

  return (
    <div className="form-group">
      <input
        {...props}
        type={props.type ? props.type : "text"}
        className={`form-control mui ${className} ${errorClass}`}
        name={props.name}
      />
      {props.label && (
        <label htmlFor={props.name} className={props?.labelClass}>{props.label} {props.icon}</label>
      )}
    </div>
  )
}

export default connect()(Register)