/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Button, Input, Select, DatePicker, Modal, Icon, SelectActions } from "../../components";
import { Constants, Globals, axios } from "../../utils";
import AddIcon from '../../assets/icons/add.png';
import TrashIcon from '../../assets/icons/trash.png';
import CreateEditClient from '../clients/create_edit_client';

function parse(value) {
  return parseFloat(parseFloat(value).toFixed(2));
}

const INITIAL_STATE = {
	client_id: '',
	comments: '',
	expiration_date: '',
  products: [],
  show_taxes: Constants.QUOTES.SHOW_TAXES.NO,
	subtotal: 0,
	iva: 0,
	iva_bs: 0,
	total: 0,
	discount: 0,
	discount_percentage: '',
  search: '',
  credit_days: null,
  status_payment: Constants.PAYMENT_TYPES.PAID,
}

class CreateEditQuote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: INITIAL_STATE,
      client: null,
      modalProducts: false,
      disabled_button: false,
      visible_client: false,
      created_client: null,
      errors: [],
      products: [],
      data: {
        products: [],
        clients: [],
        zones: [],
        sellers: [],
        client_categories: [],
      },
      edit: false,
      textButton: "Crear",
      exchangeRate: 0,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();
  debounce = createRef();

  componentDidMount = async () => {
    this.getExchangeRate();
    this.getSellers();
    this.getZones();
    this.getClientCategories();
    await this.getClients();
    if (this.props.edit) this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  getClients = async (page = 1) => {
    axios
      .post("web/admin/quotes/clients", {
        page,
        web: true,
        admin_id: this.props.user.admin_id || this.props.user.id,
        search: this.state.form.search,
      })
      .then(async (res) => {
        if(res.data.clients){
          let clients = res.data.clients.data;

          if (this.state.created_client) {
            // Omitir el cliente creado
            clients = clients.filter(i => i.id != this.state.created_client?.id);
            // Agrega el cliente recien creado de primero
            clients.unshift(this.state.created_client);
          }

          await this.setState(state => ({
            data: { ...state.data, clients }
          }));
          // if (res.data.clients.data.length === 30) {
          //   await this.getClients(page + 1);
          // }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getZones = async () => {
    axios
      .post("web/admin/zones", {user_id: this.props.user.id})
      .then(async ({ data }) => {
        data.zones.unshift({ id: "", name: "Todos" });
        await this.setState(state => ({
          data: {
            ...state.data,
            zones: data.zones.filter(item => item.id),
          }
        }));
      })
      .catch(() => Globals.showError());
  }

  getSellers = async () => {
    Globals.setLoading();

    try {
      const filter = {
        user_id: this.props.user.id,
      };

      const res = await axios.post('web/admin/orders/sellers', filter);
      if (!res.data?.sellers) throw Error('No se encontraron los vendedores');

      const sellers = res.data.sellers.data.map(c => ({
        ...c,
        value: c.id,
        label: !!c.document ? `${ c.name } - Rif ${ c.document }` : c.name,
      }));

      await this.setState(state => ({
        data: { ...state.data, sellers }
      }));

    } catch (error) {
      Globals.showError(error?.message);
      console.log('NewRequest -> getSellers -> catch:', error)
    }

    Globals.quitLoading();
  }

  getClientCategories = async () => {
    await axios
      .post("admin/clients/categories", { user_id: this.props.user.id, is_select: true })
      .then(({ data }) => {
        data.categories.unshift({ id: "x", name: "Sin Categoría" });
        data.categories.unshift({ id: "", name: "Todas" });

        this.setState(state => ({
          data: {
            ...state.data,
            client_categories: data.categories,
          }
        }));
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getExchangeRate = async () => {
    try {
      const filter = { user_id: this.props.user.id };

      const res = await axios.post('admin/conversions', filter);
      if (!res.data?.conversion) throw Error('Debe establecer una tasa de cambio para continuar');

      this.setState({ exchangeRate: res.data?.conversion?.amount || 0 });

    } catch (error) {
      Globals.showError(error?.message);
      console.log('NewRequest -> getExchangeRate -> catch:', error)
    }
  };

  edit = async () => {
    let documents = {};
    const { element } = this.props.edit;

    let products_element = []
    element.details.map((item,index) => {
      let product = []
      if(item.product){
        product = item.product;
        product.quantity = item.quantity;
        product.discount_percentage = item.discount_percentage;
        product.size_id = item.size_id;
        product.color_id = item.color_id;
        product.selected_price = item.price;
        products_element.push(product);
      }
    })

    await this.setState({
      form: {
        id: element.id,
        quote_id: element.id,
        client_id: element.client_id,
        comments: element.comments,
        discount_percentage: element.discount_percentage,
        expiration_date: moment(element.expiration_date).isValid() ? moment(element.expiration_date).toDate() : '',
        show_taxes: element.show_taxes,
        iva: element.iva,
        credit_days: element?.credit_days,
        status_payment: element?.status_payment,
      },
      products: products_element,
      edit: true,
      textButton: "Editar",
    });

    this.setTotals();
  };

  handleChange = async (emitter) => {
    const { name, value } = emitter.target;

    await this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });

		const watching = ['show_taxes','discount_percentage'];
		if (watching.includes(name)) {
			this.setTotals();
		}
  };

  checkedChange = async (newValue) => {
    await this.setState(prevState => ({
      form: {
        ...prevState.form,
        show_taxes: newValue == true ? Constants.QUOTES.SHOW_TAXES.YES : Constants.QUOTES.SHOW_TAXES.NO
      }
    }));
    this.setTotals();
  }

	delete = (index) => {
		Globals.confirm('¿Desea eliminar el producto de la cotización?', async () => {
			let products = [ ...this.state.products ];
			products.splice(index,1);
			await this.setState({ products });
			this.setTotals();
		});
	}

  /*
	changeManual = async (index, quantity) => {
    let products = [...this.state.products];
		products[index].quantity = quantity;
		await this.setState({ products });
    this.setTotals();
	}
  */
  changeManual = async (index, quantity) => {
    let products = [...this.state.products];
    let product = products[index];
  
    // Validar según el valor de decimal_stock
    if (product.decimal_stock == Constants.DECIMAL_STOCK.NO) {
      // Restringir a enteros si decimal_stock no permite decimales
      quantity = quantity.replace(/\D/g, ''); // Eliminar cualquier carácter no numérico
    } else if (product.decimal_stock == Constants.DECIMAL_STOCK.YES) {
      // Permitir decimales con una sola coma
      quantity = quantity.replace(/[^0-9,]/g, ''); // Eliminar caracteres no numéricos excepto la coma
      const parts = quantity.split(',');
      if (parts.length > 2) {
        return; // Evitar múltiples comas decimales
      }
    }
  
    // Actualizar el producto con el valor procesado
    product.quantity = quantity;
  
    // Actualizar el estado
    await this.setState({ products });
    this.setTotals();
  };  

	changeValue = async (value,target,index,callback = () => {}) => {
		let products = this.state.products;
		products[index][target] = value;

		await this.setState({
			products
		},() => callback());
    this.setTotals();
	}

	addOne = async (index) => {
		let products = [ ...this.state.products ];
    let product = products[index];

    const qty = product?.decimal_stock == Constants.DECIMAL_STOCK.YES
        ? parseFloat(String(product.quantity).replace(',', '.')) 
        : parseInt(product.quantity, 10);

		product.quantity = qty + 1;
		await this.setState({ products });
    this.setTotals();
	}

  /*
	removeOne = async (index) => {
		let products = [ ...this.state.products ];
		if (products[index].quantity > 1) {
			products[index].quantity--;
			await this.setState({ products });
			this.setTotals();
		}
	}
  */
  removeOne = async (index) => {
    let products = [...this.state.products];
    if (products[index].quantity > 1) {
      let product = products[index];

      const qty = product?.decimal_stock == Constants.DECIMAL_STOCK.YES
        ? parseFloat(String(product.quantity).replace(',', '.'))
        : parseInt(product.quantity, 10);

      // Restar 1 y redondear si es necesario
      product.quantity = product?.decimal_stock == Constants.DECIMAL_STOCK.YES
        ? parseFloat((qty - 1).toFixed(2)) // Limitar a 2 decimales
        : qty - 1;

      await this.setState({ products });
      this.setTotals();
    }
  };  

  choseProducts = async (products = []) => {
    const { products: oldProducts } = this.state;
    const isSizeColor = (type) => Number(type) === Constants.PRODUCTS_TYPE.SIZE_COLOR;

    const nonAdded = products
      .filter(np => !oldProducts.some(op => op.id === np.id))
      .map(p => ({ ...p, quantity: 1, selected_price: isSizeColor(p.type) ? '' : p.sale_price }));

    await this.setState({
      products: [ ...oldProducts, ...nonAdded ],
    });
    this.setTotals();
  }

	enableSubmit = () => {
		return this.setState({
			disabled_button: false
		});
	}

	submit = async () => {
    if (!this.state.products.length) {
			Globals.showWarning("Por favor, seleccione al menos un producto");
			this.enableSubmit();
			return false;
    }

		if (this.state.products.filter(i => {
			const product = this.state.products.find(_i => _i.id == i.id);
			return product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR && !i.size_id && !i.color_id;
		}).length > 0) {
			Globals.showWarning("Por favor, ingrese el color/talla de los productos que lo requieran");
			this.enableSubmit();
			return false;
		}

		for (let index = 0; index < this.state.products.length; index++) {
			const i = this.state.products[index];
			const product = this.state.products.find(_i => _i.id == i.id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const item_find = this.state.products.find((item,_index) => {
					if (index != _index) {
						if (item.id == i.id && item.color_id == i.color_id && item.size_id == i.size_id) {
							return true;
						}
					}

					return false;
				});

				if (item_find) {
					const color = this.props.colors.find(_i => _i.id == i.color_id);
					const size = this.props.sizes.find(_i => _i.id == i.size_id);
					const product = this.state.products.find(_i => _i.id == i.id);
					Globals.showWarning(`Lo sentimos, el producto ${ product?.name } (${ [size?.name,color?.name].filter(i => i != null).join(',') }) se encuentra más de una vez en la cotización`);
					this.enableSubmit();
					return false;
				}
			}
		}

		for (let i = 0; i < this.state.products.length; i++) {
			const item = this.state.products[i];
			const product = this.state.products.find(_i => _i.id == item.id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const size_color = product?.size_colors?.find((i) => {
					if (item.size_id && item.color_id) {
						return i.size_id == item.size_id && i.color_id == item.color_id;
					}
					else if (item.size_id) {
						return i.size_id == item.size_id && i.color_id == null;
					}
					else if (item.color_id) {
						return i.color_id == item.color_id && i.size_id == null;
					}
				});
				if (!size_color) {
					const color = this.props.colors.find(_i => _i.id == item.color_id);
					const size = this.props.sizes.find(_i => _i.id == item.size_id);
					Globals.showWarning(`Lo sentimos, el producto ${ product?.name } (${ [size?.name,color?.name].filter(i => i != null).join(',') }) no se encuentra en inventario`);
					this.enableSubmit();
					return false;
				}
			}
		}

		if (this.state.products.filter(i => !i.quantity || i.quantity == 0).length > 0) {
			Globals.showWarning("Debe seleccionar la cantidad de todos los productos");
			this.enableSubmit();
			return false;
		}

    if (!this.state.form.client_id) {
      Globals.showWarning("El campo Cliente es requerido");
      this.enableSubmit();
      return false;
    }

    if (!this.state.form.expiration_date) {
      Globals.showWarning("El campo Fecha de Vencimiento es requerido");
      this.enableSubmit();
      return false;
    }

		if (this.state.form.discount_percentage !== '' && this.state.form.discount_percentage < 0) {
			Globals.showWarning('El porcentaje de descuento general no puede ser negativo');
      this.enableSubmit();
      return false;
    }

		if (this.state.form.discount_percentage !== '' && this.state.form.discount_percentage > 100) {
			Globals.showWarning('El porcentaje de descuento general no puede ser mayor a 100%');
      this.enableSubmit();
      return false;
    }

		const details = this.state.products.map(x => ({
			...x,
			discount_percentage: x.discount_percentage || 0,
			discount: x.discount || 0,
		}));

    // Validación de porcentage de descuento de cada producto
    let error_porcentage_product = false
    details.forEach(product => {
      if (product.discount_percentage < 0) {
        Globals.showWarning(`El descuento porcentual del producto ${product.name} no puede ser negativo.`);
        error_porcentage_product = true
        return
      }

      if(product.discount_percentage > 100){
        Globals.showWarning(`El descuento porcentual del producto ${product.name} no puede ser mayor a 100%.`);
        error_porcentage_product = true
        return
      }
    });
    if(error_porcentage_product == true){
      this.enableSubmit();
      return false;
    }

    Globals.setLoading();

    try {
      await axios.post(this.state.edit ? 'admin/quotes/edit' : 'admin/quotes/create', {
        seller_id: this.props.user.id,
        ...this.state.form,
        details: JSON.stringify(details),
        currency: this.props.currency,
        admin_id: this.props.user.admin_id || this.props.user.id,
        user_id: this.props.user.id,
        expiration_date: this.state.form.expiration_date && moment(this.state.form.expiration_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
        discount_percentage: this.state.form.discount_percentage || 0,
      });
      this.state.edit ? Globals.showSuccess("Se ha editado su cotización correctamente") : Globals.showSuccess("Se ha realizado su cotización correctamente");
      this.props.onClose();

    } catch (error) {
      Globals.showError("Ocurrió un error inesperado");
      this.enableSubmit();

    } finally {
      Globals.quitLoading();
    }
	}

	close = async () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({ modalProducts: false });
	}

	setTotals = async () => {
		const { exchangeRate, form, products } = this.state;

		let subtotal = 0;
		let discount = 0;
		let iva = 0;
		let iva_bs = 0;
		let total = 0;

		for (const product of products) {
      const qty = product?.decimal_stock == Constants.DECIMAL_STOCK.YES
        ? parseFloat(String(product.quantity).replace(',', '.')) 
        : parseInt(product.quantity, 10); // Convertir a entero si no se permiten decimales

      const subtotalWithoutDiscount = parse(qty * product.selected_price);
			const prodDiscountPercentage = product.discount_percentage || 0;
			const genDiscountPercentage = form.discount_percentage || 0;

			const prodDiscountAmount = prodDiscountPercentage > 0 
				? parse((subtotalWithoutDiscount * prodDiscountPercentage) / 100)
				: 0;

			const genDiscountAmount = genDiscountPercentage > 0
				? parse(((subtotalWithoutDiscount - prodDiscountAmount) * genDiscountPercentage) / 100)
				: 0;

			if (!!form.show_taxes && !product.exento) {
				const subtotalWithDiscounts = parse(subtotalWithoutDiscount - prodDiscountAmount - genDiscountAmount);
				product.iva = parse((subtotalWithDiscounts * Constants.TAXES.IVA) / 100);
			} else {
				product.iva = 0;
			}

			product.subtotal = subtotalWithoutDiscount;
			product.discount = prodDiscountAmount;
			product.total = parse(subtotalWithoutDiscount - prodDiscountAmount - genDiscountAmount + product.iva);

      subtotal += subtotalWithoutDiscount;
			discount += (prodDiscountAmount + genDiscountAmount);
			iva += product.iva;
			iva_bs += parse(product.iva * exchangeRate);
			total += product.total;
		}

		await this.setState({
			products,
			form: {
				...this.state.form,
				subtotal,
				discount,
				iva,
				iva_bs,
				total,
			},
    })
  }

  getPrices = (product) => {
    let size_color = null;
    let prices = product.prices;

    if (product?.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
      size_color = product?.size_colors?.find((i) => {
        if (product.size_id && product.color_id) {
          return i.size_id === product.size_id && i.color_id === product.color_id;
        }
        else if (product.size_id) {
          return i.size_id === product.size_id && !i.color_id;
        }
        else if (product.color_id) {
          return i.color_id === product.color_id && !i.size_id;
        }

        return null;
      });

      prices = size_color?.prices || [];
      product.price = (product?.size_colors.length > 0 ? product?.size_colors[0].sale_price : 0);

    } else {
      product.price = product.sale_price;
    }

    return [
      {
        value: size_color?.sale_price || product.price,
        label: `$${Globals.formatMiles(size_color?.sale_price || product.price,true)}`,
      },
      ...prices.map(i => ({
        value: i.price,
        label: `$${Globals.formatMiles(i.price,true)} ${i.hasOwnProperty('unit_description') && i.unit_description ? ` ${i.unit_description}` : ''}`,
      }))
    ]
  }

  change = async (value, target) => {
    await this.setState(state => ({ form: { ...state.form, [target]: value } }));
  }

  debouncingSearchClient = async (search) => {
    if (this.debounce.current) clearTimeout(this.debounce.current);

    await this.change(undefined, 'client_id');
    await this.change(search, 'search');
    this.debounce.current = setTimeout(async () => {
      this.getClients();
    }, 1000);
  }

  toggleClientModal = () => {
    this.setState(state => ({ visible_client: !state.visible_client }));
  }

  setPaymentType = (status_payment) => {
		this.setState({
			form: {
				...this.state.form,
				status_payment
			}
		});
	}

  render() {
    const { submitted, products, data, disabled_button, visible_client } = this.state;
    const can_include_taxes = Globals.canIncludeTaxes(this.props.user)
    const filtered_clientCategories = data.client_categories.filter(Item => {
      return Item.id && Item.id > 0;
    });

    return (
      <form onSubmit={this.submit}>
        <div className="container-create-edit-quote">
          <Modal
            title="Seleccionar producto"
            onClose={() => this.close()}
            visible={this.state.modalProducts}
          >
            <SelectProduct
              onSelect={this.choseProducts}
              onClose={() => this.close()}
            />
          </Modal>

          <Modal
            size="lg"
            className="modal-report-sale-client"
            title="Registrar cliente"
            onClose={this.toggleClientModal}
            visible={visible_client}
          >
            <CreateEditClient
              user={this.props.user}
              createFromPOS={true}
              zones={data.zones}
              onSuccess={client => this.setState({ created_client: client })}
              onClose={(reload = false) => {
                if (reload) this.getClients();
                this.toggleClientModal();
              }}
              client_categories={filtered_clientCategories}
              sellers={data.sellers}
            />
          </Modal>

          {this.state.modalProducts && (
            <div
              className="backdrop"
              onClick={() => this.close()}
            />
          )}

          <div className="d-flex justify-content-end">
            <button
              className="add"
              type="button"
              title="Agregar producto"
              onClick={() => {
                document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
                this.setState({ modalProducts: true })
              }}
            >
              <img src={AddIcon} alt="Agregar producto" />
            </button>
          </div>

          {products.length === 0 && (
            <div className="no-items">No hay productos en la cotización</div>
          )}

          {products.map((product, index) => {
            const isSizeColor = Number(product?.type) === Constants.PRODUCTS_TYPE.SIZE_COLOR;
            let label_total = '(' + product.quantity + ' x ' + Globals.formatMiles(product.selected_price,true) + ')';

            if (product.discount_percentage != null && product.discount_percentage != '') {
              label_total += ' - ' + Globals.formatMiles(product.discount_percentage,true,'') + '%';
            }

            if (!!this.state.form.show_taxes && !product.exento) {
              label_total += ' + ' + Globals.formatMiles(product.iva,true) + ' IVA';
            }

            return (
              <div key={`product-${index}`} className="product-item">
                <div className="product-image">
                  <img src={ Globals.fromPhotos(product?.photo?.file) } alt="Foto del producto" />
                </div>

                <div className="product-details">
                  <div className="product-name" title={ product.name }>{ product.name }</div>

                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', marginTop: '0.5rem' }}>
                    <Select
                      color="gray"
                      label="Precio"
                      defaultname="Seleccione"
                      className="material flex-1"
                      labelClass="material"
                      disabledFirst={false}
                      //onChange={value => this.changeValue(value, 'selected_price', index)}
                      onChange={ e => {
                        if (Globals.validateDouble(e.target.value) || e.target.value === '') {
                          this.changeValue(e.target.value,'selected_price',index);
                        }
                      } }
                      value={product.selected_price}
                      options={this.getPrices(product)}
                    />

                    <div className="form-group flex-1">
                      <label className="material">Descuento (%)</label>
                      <NumberFormat
                        value={product.discount_percentage}
                        className="form-control material"
                        placeholder="Opcional"
                        allowNegative={false}
                        decimalScale={2}
                        onValueChange={v => this.changeValue(v.value,'discount_percentage',index)}
                      />
                    </div>
                  </div>

                  <div className="product-price">
                    <span>$ { Globals.formatMiles(product.total, true) } { label_total }</span>
                  </div>

                  <div className="product-qty-section">
                    <div className="product-qty-button" onClick={() => this.removeOne(index)}>
                      <span>-</span>
                    </div>
                    <input
                      className="product-qty"
                      value={product.quantity}
                      onChange={e => this.changeManual(index, e.target.value)}
                    />
                    <div className="product-qty-button" onClick={() => this.addOne(index)}>
                      <span>+</span>
                    </div>
                  </div>

                  {
                    isSizeColor && (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {
                          [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].length > 0 && (
                            <div style={{ flex: 1 }}>
                              <div>Color:</div>
                                <div className="colors">
                                  {
                                    [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].map((_item) => {
                                      return (
                                        <div
                                          className={`color ${product.color_id == _item ? 'selected' : ''}`}
                                          style={{ backgroundColor: product?.size_colors?.find(x => x.color_id === _item)?.color?.hex }}
                                          onClick={ () => {
                                            this.changeValue(Number(_item),'color_id',index,() => {
                                              const size_color = product?.size_colors?.find((i) => {
                                                if (product.size_id && product.color_id) {
                                                  return i.size_id == product.size_id && i.color_id == product.color_id;
                                                }
                                                else if (product.size_id) {
                                                  return i.size_id == product.size_id && i.color_id == null;
                                                }
                                                else if (product.color_id) {
                                                  return i.color_id == product.color_id && i.size_id == null;
                                                }
                                              });
                                              if (size_color)
                                                this.changeValue(size_color?.sale_price,'price',index,() => {
                                                  this.changeValue(size_color?.sale_price,'selected_price',index);
                                                });
                                            });
                                        } } />
                                      )
                                    })
                                  }
                              </div>
                            </div>
                          )
                        }
                        {
                          [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].length > 0 && (
                            <div style={{ flex: 1 }}>
                              <div>Talla:</div>
                              <Select
                                disabledFirst={false}
                                onChange={ e => {
                                  this.changeValue(Number(e.target.value),'size_id',index,() => {
                                    const size_color = product?.size_colors?.find((i) => {
                                      if (product.size_id && product.color_id) {
                                        return i.size_id == product.size_id && i.color_id == product.color_id;
                                      }
                                      else if (product.size_id) {
                                        return i.size_id == product.size_id && i.color_id == null;
                                      }
                                      else if (product.color_id) {
                                        return i.color_id == product.color_id && i.size_id == null;
                                      }
                                    });
                                    if (size_color)
                                      this.changeValue(size_color?.sale_price,'price',index,() => {
                                        this.changeValue(size_color?.sale_price,'selected_price',index);
                                      });
                                  });
                                } }
                                options={ [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].map(_item => {
                                  const size = product.size_colors?.find(i => i.size_id === _item);
                                  return {
                                    value: size?.size?.id,
                                    label: size?.size?.name,
                                  }
                                }) }
                                value={ product.size_id }
                                className="material"
                                labelClass="material"
                                placeholder="Todos"
                              />
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>

                <div className="product-trash">
                  <img
                    src={TrashIcon}
                    title="Eliminar de la lista"
                    alt="Eliminar"
                    onClick={() => this.delete(index)}
                  />
                </div>
              </div>
            )
          })}

          <div className="total-price">
            <div>SUBTOTAL:</div>
            <div>$ { Globals.formatMiles(this.state.form.subtotal) }</div>
          </div>
          <div className="total-price" style={{ marginTop: '0.5rem' }}>
            <div>DESCUENTO:</div>
            <div>$ { Globals.formatMiles(this.state.form.discount) }</div>
          </div>
          {this.state.form.show_taxes === Constants.QUOTES.SHOW_TAXES.YES && (
            <div className="total-price" style={{ marginTop: '0.5rem' }}>
              <div>IVA:</div>
              <div>$ { Globals.formatMiles(this.state.form.iva) }</div>
            </div>
          )}
          <div className="total-price" style={{ marginTop: '0.5rem' }}>
            <div>TOTAL:</div>
            <div>$ { Globals.formatMiles(this.state.form.total) }</div>
          </div>

          <div className="row">
            <div className="tab-container">
              <div
                className={`tab ${this.state.form.status_payment === Constants.PAYMENT_TYPES.PAID ? 'selected' : ''}`}
                onClick={() => this.setPaymentType(Constants.PAYMENT_TYPES.PAID)}
              >
                Contado
              </div>
              <div
                className={`tab ${this.state.form.status_payment === Constants.PAYMENT_TYPES.TO_PAY ? 'selected' : ''}`}
                onClick={() => this.setPaymentType(Constants.PAYMENT_TYPES.TO_PAY)}
              >
                Crédito
              </div>
            </div>
          </div>

          <div className="row">
            <DatePicker
              color="white"
              label="Fecha de Expiración"
              // minDate={ moment().add(1,'day').format('DD-MM-YYYY') }
              className="material"
              value={this.state.form.expiration_date}
              onChange={value => this.handleChange({ target: {value, name: 'expiration_date'}})}
            />

            <SelectActions
              label="Cliente"
              placeholder="Seleccione"
              isSearchable
              actionOptions={[
                {
                  onClick: () => this.toggleClientModal(),
                  content: (
                    <div style={{ color: '#3A7FC2' }}>
                      <span>Registrar nuevo cliente</span>
                      <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.25rem' }} />
                    </div>
                  ),
                }
              ]}
              options={data.clients.map(i => ({
                value: i.id,
                label: i.name,
                actions: [],
              }))}
              value={this.state.form.client_id}
              onChange={id => this.change(id, 'client_id')}
              onInputChange={this.debouncingSearchClient}
            />
          </div>

          <div className="row">

            {this.state.form.status_payment === Constants.PAYMENT_TYPES.TO_PAY && (
              <Input
                type="number"
                label="Días de Crédito"
                className="material"
                labelClass="material"
                value={this.state.form.credit_days}
                onChange={e => {
                  if (Globals.validateInteger(e.target.value) || e.target.value === '') {
                    this.change(e.target.value, 'credit_days');
                  }
                }}
              />
            )}

            <div className="form-group">
              <label className="material">Descuento (%)</label>
              <NumberFormat
                value={this.state.form.discount_percentage}
                className="form-control material"
                placeholder="Opcional"
                allowNegative={false}
                decimalScale={2}
                onValueChange={v => this.handleChange({ target: { value: v.value, name: 'discount_percentage' } })}
              />
            </div>

          </div>
          

          <Input
            color=" "
            value={this.state.form.comments}
            name="comments"
            label="Agregar información adicional (Opcional)"
            className="material"
            labelClass="material"
            onChange={this.handleChange}
          />

          {can_include_taxes && (
            <div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '8rem' }}>
              <input
                name="show_taxes"
                id="exento-check"
                type="checkbox"
                checked={this.state.form.show_taxes === Constants.QUOTES.SHOW_TAXES.YES}
                style={{ marginRight: '0.5rem' }}
                onChange={() => this.checkedChange(!this.state.form.show_taxes)}
              />
              <label className="" htmlFor="exento-check" style={{ fontSize: 20, marginBottom: 0 }}>
                Incluir Impuesto
              </label>
            </div>
          )}

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button" className="d-flex justify-content-center">
              <Button
                color=" "
                type="button"
                className="primary"
                disabled={disabled_button}
                onClick={() => this.submit()}
              >
                <span>{this.state.textButton}</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const SelectProduct = ({ onSelect, onClose }) => {
  const user = useSelector(state => state.user);
  const adminId = user.level_id === Constants.ROLE_ADMIN ? user.id : user.admin_id;

  const { products, search, fetchMore, isLoading, pagination } = useFetch(adminId);
  const [selected, setSelected] = useState([]);

  const select = (item) => {
    if (selected.includes(item.id)) {
      const others = selected.filter(x => x !== item.id);
      setSelected(others);
    } else {
      setSelected([ ...selected, item.id ]);
    }
  }

  const submit = () => {
    onSelect(products.filter(x => selected.includes(x.id)));
    onClose();
  }

  return (
    <div id="container-create-edit-quote-select-product">
      <Input
        color=" "
        placeholder="Buscar por nombre o código"
        className="material"
        labelClass="material"
        onChange={e => search(e.target.value)}
      />

      {!!selected.length && (
        <div className="product-totals">
          <span>Seleccionados: { selected.length }</span>
          <button
            type="button"
            className="done"
            title="Agregar a la cotización"
            onClick={() => submit()}
          >
            <Icon name="check" />
          </button>
        </div>
      )}

      {products.map((item, index) => {
        const isSizeColor = Number(item?.type) === Constants.PRODUCTS_TYPE.SIZE_COLOR;
        const isSelected = selected.includes(item.id);

        return (
          <div
            key={`product-item-${index}`}
            className={`product-item ${isSelected ? 'selected':''}`}
            onClick={() => select(item)}
          >
            <div className="product-image">
              <img src={ Globals.fromPhotos(item?.photo?.file) } alt="Producto" />
            </div>
            <div className="product-details">
              <span className="product-name" title={item?.code} style={{ fontWeight: 'bold', fontSize: '12px' }}>Cod. {item?.code}</span>
              <span className="product-name" title={item?.name}>{ item?.name }</span>
            </div>
            <div>{ isSizeColor ? '' : '$' + item?.sale_price }</div>
          </div>
        )
      })}

      <div className="d-flex justify-content-center mt-4">
        {isLoading && <span>Cargando...</span>}
        {(!isLoading && pagination.page < pagination.last_page) && (
          <Button onClick={() => fetchMore()}>
            <span>Cargar más</span>
          </Button>
        )}
      </div>
    </div>
  );
}

const useFetch = (admin_id) => {
  const initialForm = {
    web: true,
    admin_id,
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res = await axios.post('web/admin/quotes/products', pagination);

      if (!res?.data?.products)
        return Globals.showError('Ocurrió un error inesperado');

      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.products?.total,
        last_page: res.data?.products.last_page,
      });

      const newData = pagination.page > 1
        ? [ ...data, ...res.data?.products?.data ]
        : res.data?.products?.data;

      setData(newData);

    } catch (error) {
      Globals.showError('Ocurrió un error inesperado');
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const fetchMore = (page = pagination.page + 1) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    products: data,
    pagination,
    fetchMore,
    reload,
    isLoading: canFetch,
    search,
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditQuote);
