import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal as BModal } from "react-bootstrap";

import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Input,
  SelectActions,
  Select,
} from "../../components";
import { axios, Globals, Format, Constants, ENV } from "../../utils";

// Components
import Menu from "../menu";
import CreateEditProduct from "./create_edit_product";
import ViewProduct from "./view_product";
import RemoveProduct from "./remove_product";
import AddIcon from '../../assets/icons/add.png';
import uploadExcelDesktop from '../../assets/icons/upload-excel-desktop.png';
import ExcelFile from '../../assets/icons/excel-file.png';
import UploadFile from '../../assets/icons/upload.png';
import PrinterIcon from '../../assets/icons/printer.png';
import Habladores from './habladores';

class Products extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      header: ["Nombre", "Código", "Categoría","Precio\nCompra","Precio\nVenta","Stock","Acciones"],
      modalUploadExcel: false,
      modalHabladores: false,
      page: 1,
      last_page: 1,
      data: [],
      companies: [],
      brands: [],
      categories: [],
      product: null,
      user: this.props.user,
      view: false,
      remove: false,
      providers: {},
      list_status: [
        { value: 0, label: "Suspendidos" },
        { value: 1, label: "Activos" }
      ],
      form: {
        status: "",
        search: "",
        user_id: this.props.user.id,
        file: {},
        currentFile: "",
        web: true,
        withPhoto: true,
        withoutStock: false,
        stock: true,
        category_id: "",
        w_stock: "",
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? 
          this.props.user.warehouse_principal : 
          this.props.user.warehouse_id !== null ? 
          this.props.user.warehouse_id : 
          this.props.user.warehouse_principal,
        //warehouse_id: 0,
      },
      selectedPrices: [
        { value: "1", label: "Precio principal" },
        { value: "2", label: "Precio adicional 1" },
        { value: "3", label: "Precio adicional 2" }
      ],
      list_categories: [],
      form_habladores: {
        currency: Constants.CURRENCIES.DOLARES,
        category_id: null,
        select_price: 1,
      },
      list_stock: [
        { value: 1, label: "Con Stock" },
        { value: 0, label: "Sin Stock" }
      ],
      warehouses: [],
      showEditButton: false,
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.products();
    //this.categories();
    this.brands();
    this.getWarehouses();
  };

  products = () => {
    let param = this.state.form;

    Globals.setLoading();
    axios
      .post("admin/inventory?page=" + this.state.page, param)
      .then(res => {
        if (res.data) {
          const categoriesMap = res.data.categories.map(({ id, name }) => ({
            value: id,
            label: name
          }));
          this.setState({
            last_page: res.data.products.last_page,
            data: res.data.products.data,
            categories: res.data.categories,
            list_categories: categoriesMap
          });

          if(param.warehouse_id !== 0 && param.warehouse_id !== "0") {
            this.setState({
              showEditButton: true
            });
          }
          else if(param.warehouse_id == 0 && param.warehouse_id == "0"){
            this.setState({
              showEditButton: false
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => Globals.quitLoading());

    console.log(param)
  };

  getWarehouses = async () => {
    try {
      const data = {
        web: true,
        user_id: this.props.user.id,
        is_select: true,
        minimize: true,
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? null : this.props.user.warehouse_id,
      };

      const res = await axios.post('web/admin/warehouses', data);
      if (res.data?.warehouses) {
        const formatWarehouses = [
          { value: 0, label: "Todos los almacenes" },
          ...res.data.warehouses.sort((a, b) => a.id - b.id).map(Item => ({
            value: Item.id,
            label: Item.name
          }))
        ];

        // const mainIdx = formatWarehouses.findIndex(x => x.value === this.props.user.warehouse_principal);
        // if (mainIdx >= 0) {
        //   const main = formatWarehouses[mainIdx];
        //   formatWarehouses.splice(mainIdx, 1);
        //   formatWarehouses.unshift(main);
        // }

        this.setState({
          warehouses: formatWarehouses
        });
      }
    } catch (error) {
      Globals.showError();
      console.log('NewRequest -> getWarehouses -> catch: ', error);
    }
  };

  brands = () => {
    const { user } = this.props;
    let param = {
      user_id: user.id
    };
    axios.post('web/admin/brands?page=', param)
			.then(async res => {
				if (res.data) {
          await this.setState({
            brands: res.data.brands
          });
				}
			})
			.catch(err => {
				Globals.showError();
			})
  };

  categories = () => {
    const { user } = this.props;
    let param = {
      user_id: user.id
    };
    axios
    .post("web/admin/categories?page=", param)
    .then(async res => {
      if (res.data.result) {
        await this.setState({
          categories: res.data.categories.data
        });
      }
    })
    .catch(err => {
      Globals.showError();
    })
    .then(() => {
      Globals.quitLoading();
    });
  };

  downloadFormat = () => {
    Globals.setLoading();
    const { user } = this.props;

    let param = {
      user_id: user.id
    };

    axios
      .post("admin/inventory/download-format", this.state.form)
      .then(res => {
        if(res.data.url_storage){
          const url = `${ENV.BasePublic}${res.data.url_storage}`;

          const element = document.createElement('a');
          element.href = url;
          element.download = 'Carga_Masiva_Productos';
          element.click();

          Globals.quitLoading()
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
  };

  close = () => {
    this.setState(
      {
        create: false,
        view: false,
        remove: false,
        product: null,
        edit: null,
        user: null,
        modalUploadExcel: false,
        modalHabladores: false,
      },
      () => this.load()
    );
  };

  delete = (item) => {
    let data = {
      user_id: this.props.user.id,
      product_id: item.id,
      warehouse_id: this.state.form.warehouse_id,
    }
    Globals.confirm("¿Desea eliminar el producto: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("web/admin/inventory/delete", data)
        .then(() => {
          Globals.showSuccess("¡Producto eliminado exitosamente!");
          this.load();
        })
        .catch(({ response }) => Globals.showWarning(response.data.error))
        .then(() => Globals.quitLoading());
    });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  chooseFile = async (e) => {
    const currentFile = URL.createObjectURL(e.target.files[0]);

    const data = {
      ...this.state.form,
      file: e.target.files[0],
      currentFile: currentFile,
    };

    Globals.confirm('¿Realmente desea subir el archivo?', async () => {
      await this.setState({ form: { ...data } });

      Globals.setLoading();
      axios
        .upload("admin/inventory/import-excel", data)
        .then(async res => {
          if(res.data.result){
            Globals.showSuccess(res.data.msg);
            
            await this.setState({
              form: {
                ...this.state.form,
                file: {},
                currentFile: "",
              }
            });
            this.products();
          }
          else{
            if(res?.data?.error){
              switch (res?.data?.error) {
                case Constants.CARGA_MASIVA_PRODUCTS_ERROR.ROWS:
                  Globals.showError('El archivo Excel adjuntado no tiene el formato correcto. Por favor, descargue el formato adjuntado para usarlo como guía.');
                  break;
                case Constants.CARGA_MASIVA_PRODUCTS_ERROR.CODE:
                  if(res?.data?.msg){
                    Globals.showError(res?.data?.msg);
                  }
                  else{
                    Globals.showError('Existe un producto con el código repetido. Intente de nuevo.');
                  }
                  break;
                case Constants.CARGA_MASIVA_PRODUCTS_ERROR.EMPTY_ALL_VALUES:
                case Constants.CARGA_MASIVA_PRODUCTS_ERROR.EMPTY_REQUIRED_ROW:
                  Globals.showError('Los campos código y nombre son requeridos.');
                  break;
                case Constants.CARGA_MASIVA_PRODUCTS_ERROR.INVALID_UNIT:
                  Globals.showError('El tipo de unidad del producto ingresado no existe. Por favor intente nuevamente.');
                  break;
              }
              this.close()
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
        .then(() => Globals.quitLoading());
    });
  }

  print = (data) => {
    Globals.typeDownloadOptions(
      `Selecciona en que formato deseas que se exporte el reporte`,
      (type_download) => {
        axios
          .post("admin/inventory/download", { ...this.state.form, type_download })
          .then(res => {
            if(res.data.url){
              //const url = res.data.url;
              const url = `${res.data.url}`;
              const win = window.open(url, "_blank");
              win.focus();
            }
            else{
              Globals.showError('Ha ocurrido un error');
            }
          })
          .catch(err => {
            Globals.showError();
          });
      }
    );
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        status: "",
        search: "",
        user_id: this.props.user.id,
        file: {},
        currentFile: "",
        web: true,
        withPhoto: true,
        withoutStock: false,
        stock: true,
        category_id: "",
        w_stock: "",
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? 
          this.props.user.warehouse_principal : 
          this.props.user.warehouse_id !== null ? 
          this.props.user.warehouse_id : 
          this.props.user.warehouse_principal,
        //warehouse_id: 0,
        showEditButton: false,
      },
    }));
    await this.load();
  };

  render() {
    const { view, create, remove, product, modalUploadExcel, modalHabladores, list_categories, selectedPrices, showEditButton } = this.state;
    const user = this.props.user
    console.log(showEditButton)

    return (
      <Menu history={this.props.history}>
        {view && (
          <Modal title="Ver Producto" onClose={this.close} visible>
            <ViewProduct data={this.state.product} />
          </Modal>
        )}

        {remove && (
          <Modal
            title={`Eliminar ${product.name}`}
            onClose={this.close}
            visible
          >
            <RemoveProduct product={product} onClose={this.close} />
          </Modal>
        )}

        {create && (
          <Modal
            className="modal-product"
            title={this.state.edit ? "Editar Producto" : "Nuevo Producto"}
            onClose={this.close}
            visible
          >
            <CreateEditProduct
              brands={this.state.brands}
              categories={this.state.categories}
              edit={this.state.edit}
              onClose={this.close}
              user={ this.props.user }
              warehouse_id={this.state.form.warehouse_id}
            />
          </Modal>
        )}

        <BModal show={modalUploadExcel} onHide={() => this.close()} size="lg" id="modal-upload-excel">
          <BModal.Body>
            <UploadExcelFile
              download={() => this.downloadFormat()}
              onUpload={this.chooseFile}
              onClose={() => this.close()}
            />
          </BModal.Body>
        </BModal>

        <BModal show={modalHabladores} onHide={() => this.close()} size="lg" id="modal-upload-excel">
          <BModal.Body>
            <Habladores 
              categories={list_categories}
              selectedPrices={selectedPrices}
              onClose={() => this.close()}
              user={user}
            />
          </BModal.Body>
        </BModal>

        <div id="products">
          <div className="row">
            {/* <div className="col-md-2">
              <Button color="green" className="btn-align-bottom" >
								Exportar
							</Button>
						</div> */}
            <div className="col-md-3">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.form.search}
                placeholder="Buscar por Nombre, Código o Descripción"
              />
            </div>
            <div className="col-md-3">
              <Select
                color="white"
                name="category_id"
                label="Categoría"
                defaultname="Todos"
                disabledFirst="false"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.category_id}
                options={this.state.list_categories}
              />
            </div>
            {user.level_id === Constants.ROLE_ADMIN || (user.level_id === Constants.ROLE_MODERATOR && user.warehouse_id === null) ? (
              <div className="col-md-3">
                <Select
                  color="white"
                  name="warehouse_id"
                  label="Almacén"
                  className="filter"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.change(emitter)}
                  value={this.state.form.warehouse_id}
                  options={this.state.warehouses}
                />
              </div>
            ) : null}
            <div className="col-md-3">
              <Select
                color="white"
                name="w_stock"
                label="Con Stock/Sin Stock"
                defaultname="Todos"
                disabledFirst="false"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.w_stock}
                options={this.state.list_stock}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-lg-4 d-flex" style={{ gap: '1rem' }}>
              <Button
                color=" "
                className="btn-align-bottom btn-filter"
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.products();
                }}
              >
                <span>Filtrar</span>
              </Button>
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>

            <div className="col-md-7" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
              <button
                className="btn btn-filter"
                style={{ flex: 1, display: 'flex', alignItems: 'center', marginTop: 20 }}
                onClick={() => this.setState({ modalHabladores: true })}
              >
                <img src={AddIcon} style={{ width: 24, marginRight: '0.5rem' }} alt="Agregar" />
                <span>Habladores</span>
              </button>

              <button
                className="btn btn-filter"
                style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 20 }}
                onClick={() => this.setState({ modalUploadExcel: true })}
              >
                <img src={AddIcon} style={{ width: 24, marginRight: '0.5rem' }} alt="Subir Excel" />
                <p style={{ lineHeight: '14px' }}>Subir masivamente productos en excel</p>
              </button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Productos"
            header={this.state.header}
            right={
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {/* <div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '8rem' }}>
                  <input
                    id="photo-check"
                    type="checkbox"
                    checked={this.state.form.withoutStock}
                    style={{ marginRight: '0.5rem' }}
                    onChange={() => this.change({ target: { value: !this.state.form.withoutStock, name: 'withoutStock' } })}
                  />
                  <label className="" htmlFor="photo-check" style={{ fontSize: 12, marginBottom: 0 }}>
                    Imprimir sin stock
                  </label>
                </div> */}

                <div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '8rem' }}>
                  <input
                    id="photo-check"
                    type="checkbox"
                    checked={this.state.form.withPhoto}
                    style={{ marginRight: '0.5rem' }}
                    onChange={() => this.change({ target: { value: !this.state.form.withPhoto, name: 'withPhoto' } })}
                  />
                  <label className="" htmlFor="photo-check" style={{ fontSize: 12, marginBottom: 0 }}>
                    Imprimir con foto del producto
                  </label>
                </div>

                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print(this.state.data)}
                >
                  <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                </Button>

                <Button
                  title="Agregar Productos"
                  small="true"
                  onClick={() => {
                    this.setState({
                      create: true
                    });
                  }}
                >
                  <img src={AddIcon} alt="Agregar Productos" />
                </Button>
              </div>
              
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: '50%' }}>{i.name}</td>
                  <td style={{ width: '5%' }}>{i.code}</td>
                  <td style={{ width: '5%' }}>
                    {i.category?.name ?? 'Sin categoría'}
                  </td>
                  <td style={{ width: '5%' }}>
                    {
                      i.size_colors.length > 0 ?
                        Globals.formatMiles(i.size_colors[0]?.purchase_price)
                      :
                        Globals.formatMiles(i.purchase_price)
                    }
                  </td>
                  <td style={{ width: '5%' }}>
                    {
                      i.size_colors.length > 0 ?
                        Globals.formatMiles(i.size_colors[0]?.sale_price)
                      :
                        Globals.formatMiles(i.sale_price)
                    }
                  </td>
                  <td style={{ width: '5%' }}>
                    {i.stock_warehouse !== undefined && i.stock_warehouse !== null
                      ? i.stock_warehouse
                      : i.stock_acumulado > 0
                        ? i.stock_acumulado
                        : i.stock}
                  </td>
                  <td>
                    <Button
                      color=" "
                      title="Ver"
                      small="true"
                      style={{backgroundColor: '#5c068c'}}
                      onClick={() =>
                        this.setState({
                          product: i,
                          view: true
                        })
                      }
                    >
                      <Icon name="eye" />
                    </Button>
                    {(showEditButton) && (
                      <Button
                        title="Editar"
                        color=""
                        small="true"
                        style={{ backgroundColor: '#0f54e0' }}
                        onClick={() =>
                          this.setState({
                            create: true,
                            edit: {
                              element: i,
                              index: index
                            }
                          })
                        }
                      >
                        <Icon name="edit" />
                      </Button>
                    )}
                    {/* <Link to={`products/providers/${i.id}`}>
                      <Button color="orange" title="Proveedores" small="true">
                        <Icon name="user" />
                      </Button>
                    </Link> */}
                    {
                      user.level_id === Constants.ROLE_ADMIN ?
                        <Button
                          color="red"
                          title="Eliminar"
                          small="true"
                          style={{ backgroundColor: '#cf3f10' }}
                          onClick={() =>
                            // this.setState({ remove: true, product: i })
                            this.delete(i)
                          }
                        >
                          <Icon name="trash" />
                        </Button>
                        :
                        user.level_id === Constants.ROLE_MODERATOR ?
                          user.permissions.length > 0 ?
                            user.permissions.some(i => i.code === Constants.PERMISSIONS.MODERATOR.PRODUCTS_DELETE) ?
                              <Button
                                color="red"
                                title="Eliminar"
                                small="true"
                                style={{ backgroundColor: '#cf3f10' }}
                                onClick={() =>
                                  // this.setState({ remove: true, product: i })
                                  this.delete(i)
                                }
                              >
                                <Icon name="trash" />
                              </Button>
                              : ''
                            : ''
                          : ''

                    }
                    
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page =>
              this.setState(
                {
                  page: page
                },
                () => this.load()
              )
            }
          />
        </div>
      </Menu>
    );
  }
}

const UploadExcelFile = ({ download, onUpload, onClose }) => (
  <>
    <div className="banner">
      <img src={uploadExcelDesktop} alt="Subir excel" />
    </div>

    <div className="content">
      <p>
        <b>Paso 1-</b> Descarga el archivo de excel
        <div>
          <button className="btn btn-primary p-2" onClick={download}>
            <img src={ExcelFile} alt="Descargar excel" />
            <span>Descargar Archivo Excel</span>
          </button>
        </div>
      </p>
      <p><b>Paso 2-</b> Carga la información de tus productos, en el orden indicado en el excel</p>
      <p><b>Paso 3-</b> Sube tu archivo excel con la información de tus productos</p>
      <p><b>Importante: esta función también permite actualizar la base de productos cargados en Sysge24, usar esta función como forma de actualización reemplaza la base de productos previamente cargada. Tenga especial atención dado a que esta acción no se puede deshacer.</b></p>
    </div>

    <div className="actions">
      <button className="btn p-2" onClick={onClose}>
        <span>Cerrar</span>
      </button>

      <label className="btn p-2" htmlFor="excel-file">
        <img src={UploadFile} alt="Descargar excel" />
        <span>Subir Formato de Productos</span>
      </label>
    </div>

    <input
      id="excel-file"
      type="file"
      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onChange={onUpload}
      style={{ display: 'none' }}
    />
  </>
);

export default connect(state => {
  return {
    user: state.user
  };
})(Products);
