import React, { Component, createRef, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-bootstrap';
import { Button, Modal, Select, SelectActions } from '../../../components';
import { Constants, Globals, axios } from '../../../utils';
import Menu from '../../menu';
import ModalProducts from './modal-products';
import CreateEditClient from '../../clients/create_edit_client';

import LocationPinIcon from '../../../assets/icons/location-pin.png';
import LeftArrowIcon from '../../../assets/icons/left-arrow.png';
import CloseIcon from '../../../assets/icons/close-black.png';

const STEP = {
  PRODUCTS: 1,
  PAYMENT: 2,
}

function parse(value) {
  return parseFloat(parseFloat(value).toFixed(2));
}

function format(value) {
  return parseFloat(value).toFixed(2);
}

class NewRequest extends Component {

  state = {
    step: STEP.PRODUCTS,
    visible_products: false,
    visible_client: false,
    isSubmittedForm: false,
    isLoadingWarehouses: false,
    created_client: null,
    form: {
      user: this.props.user,
      user_id: this.props.user.id,
      seller_id: this.props.user.id,
      search: '',
      client_id: '',
      status_payment: Constants.PAYMENT_TYPES.PAID,
      discount: '',
      initial_payment: '',
      credit_days: '',
      change: 0,
      comments: '',
      client: null,
      details: [],
      methods_selected: [],
      is_copy: false,
      warehouse_id: "",
      currency: Constants.CURRENCIES.DOLARES,
    },
    totals: {
      subtotal_usd: 0,
      subtotal_bs: 0,
      paid_usd: 0,
      paid_bs: 0,
      left_usd: 0,
      left_bs: 0,
      total_bs: 0,
      total_usd: 0,
      change_bs: 0,
      change_usd: 0,
    },
    clients: [],
    sellers: [],
    zones: [],
    methods: [],
    exchangeRate: 0,
    warehouses: [],
    formatWarehouses: [],
    clientOrdersToPay: null,
    client_categories: [],
  }

  debounce = createRef();

  componentDidMount() {
    this.getWarehouses();
    this.getClients();
    this.getSellers();
    this.getZones();
    this.getExchangeRate();
    this.getPaymentMethods();
    this.getClientCategories();
  }

  resetForm = () => {
    this.setState(state => ({
      form: {
        ...state.form,
        user_id: this.props.user.id,
        seller_id: this.props.user.id,
        search: '',
        client_id: '',
        status_payment: Constants.PAYMENT_TYPES.PAID,
        discount: '',
        initial_payment: '',
        credit_days: '',
        change: 0,
        comments: '',
        client: null,
        details: [],
        methods_selected: [],
        is_copy: false,
        warehouse_id: "",
        currency: Constants.CURRENCIES.DOLARES,
      },
      totals: {
        ...state.totals,
        subtotal_usd: 0,
        subtotal_bs: 0,
        paid_usd: 0,
        paid_bs: 0,
        left_usd: 0,
        left_bs: 0,
        total_bs: 0,
        total_usd: 0,
        change_bs: 0,
        change_usd: 0,
      },
    }));
  }

  getClients = async () => {
    Globals.setLoading();

    try {
      const filter = {
        user_id: this.state.form.user_id,
        search: this.state.form.search,
      };

      const res = await axios.post('web/admin/orders/clients', filter);
      if (!res.data?.clients) throw Error('No se encontraron los clientes');

      // Filtramos los clientes por estado activo antes de mapearlos
      const activeClients = res.data.clients.data.filter(c => c.status === Constants.STATUS_ACTIVE);

      let clients = activeClients.map(c => ({
        ...c,
        value: c.id,
        label: !!c.document ? `${c.name} - Rif ${c.document}` : c.name,
      }));

      if (this.state.created_client) {
        // Omitir el cliente creado
        clients = clients.filter(i => i.id != this.state.created_client?.id);
        // Agrega el cliente recien creado de primero
        clients.unshift({
          ...this.state.created_client,
          value: this.state.created_client.id,
          label: !!this.state.created_client.document ? `${this.state.created_client.name} - Rif ${this.state.created_client.document}` : this.state.created_client.name,
        });
      }

      this.setState({ clients });

    } catch (error) {
      Globals.showError(error?.message);
      console.log('NewRequest -> getClients -> catch:', error)
    }

    Globals.quitLoading();
  }

  getSellers = async () => {
    Globals.setLoading();

    try {
      const filter = {
        user_id: this.state.form.user_id,
      };

      const res = await axios.post('web/admin/orders/sellers', filter);
      if (!res.data?.sellers) throw Error('No se encontraron los vendedores');

      const sellers = res.data.sellers.data.map(c => ({
        ...c,
        value: c.id,
        label: !!c.document ? `${ c.name } - Rif ${ c.document }` : c.name,
      }));

      this.setState({ sellers });

    } catch (error) {
      Globals.showError(error?.message);
      console.log('NewRequest -> getSellers -> catch:', error)
    }

    Globals.quitLoading();
  }

  getZones = async () => {
    Globals.setLoading();

    axios
      .post("web/admin/zones", {user_id: this.props.user.id})
      .then(({ data }) => {
        data.zones.unshift({ id: "", name: "Todos" });
        this.setState({ zones: data.zones.filter(item => item.id) });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  }

  getExchangeRate = async () => {
    try {
      const filter = { user_id: this.state.form.user_id };

      const res = await axios.post('admin/conversions', filter);
      if (!res.data?.conversion) throw Error('Debe establecer una tasa de cambio para continuar');

      this.setState({ exchangeRate: res.data?.conversion?.amount || 0 });

    } catch (error) {
      Globals.showError(error?.message);
      console.log('NewRequest -> getExchangeRate -> catch:', error)
    }
  }

  getPaymentMethods = async () => {
    try {
      const filter = {
        user_id: this.state.form.user_id,
      };

      const res = await axios.post('web/admin/orders/methods', filter);
      if (!res.data?.methods) throw Error('No se encontraron los métodos de pago');

      this.setState({ methods: res.data?.methods });

    } catch (error) {
      Globals.showError(error?.message);
      console.log('NewRequest -> getPaymentMethods -> catch:', error)
    }
  }

  getClientCategories = async () => {
    await axios
      .post("admin/clients/categories", { user_id: this.props.user.id, is_select: true })
      .then(({ data }) => {
        data.categories.unshift({ id: "x", name: "Sin Categoría" });
        data.categories.unshift({ id: "", name: "Todas" });

        this.setState({
          client_categories: data.categories
        });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getWarehouses = async () => {
    try {
      this.setState({ isLoadingWarehouses: true });

      const data = {
        web: true,
        user_id: this.props.user.id,
        is_select: true,
        //Comentado para prod
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? null : this.props.user.warehouse_id,
        // warehouse_id: this.props.user.warehouse_principal,
        unique_by_size_color: true, //para que traiga solo una talla/color en caso de ser por talla/color
      };

      const res = await axios.post('web/admin/warehouses', data);
      if (res.data?.warehouses) {
        const formatWarehouses = res.data.warehouses.map(Item => ({
          value: Item.id,
          label: Item.name
        }));

        const mainIdx = formatWarehouses.findIndex(x => x.value === this.props.user.warehouse_principal);
        if (mainIdx >= 0) {
          const main = formatWarehouses[mainIdx];
          formatWarehouses.splice(mainIdx, 1);
          formatWarehouses.unshift(main);
        }

        this.setState({
          warehouses: res.data.warehouses,
          formatWarehouses: formatWarehouses
        });
      }
    } catch (error) {
      Globals.showError();
      console.log('NewRequest -> getWarehouses -> catch: ', error);
    }
    this.setState({ isLoadingWarehouses: false });
  };

  change = async (value, target) => {
    await this.setState(state => ({ form: { ...state.form, [target]: value } }));

    if (target === 'currency' && Number(value) === Constants.CURRENCIES.BOLIVARES && this.state.exchangeRate <= 0) {
      Globals.showError('Debe establecer una tasa de cambio para continuar');
      this.change(Constants.CURRENCIES.DOLARES, 'currency');
    }
  }

  changeTab = (step) => {
    this.setState({ step });
  }

  goToPaymentStep = () => {
    if (!this.state.form.client_id)
      return Globals.showError('Debe seleccionar el cliente');

    this.changeTab(STEP.PAYMENT);
  }

  goBack = (toPrevModule = false) => {
    if (this.state.step === STEP.PAYMENT && !toPrevModule)
      return this.changeTab(STEP.PRODUCTS);
    this.props.history.replace('/report-sale');
  }

  setTotals = async () => {
    const { form, exchangeRate } = this.state;

    let subtotal_usd = 0;
    let subtotal_bs = 0;
    let left_usd = 0;
    let left_bs = 0;
    let total_bs = 0;
    let total_usd = 0;
    let change_bs = 0;
    let change_usd = 0;
    const products = [ ...form.details ];
    const paid_usd = form.methods_selected.reduce((acc, curr) => acc + Number(curr.amount), 0);
    const paid_bs = parse(paid_usd * exchangeRate);

    for (const product of products) {
      const qty = product?.decimal_stock == Constants.DECIMAL_STOCK.YES ? product.quantity : parseInt(product.quantity)
      const subtotalWithoutDiscount = parse(qty * product.sale_price);
      const discountAmount = product.discount > 0
        ? (subtotalWithoutDiscount * product.discount) / 100
        : 0;

			product.subtotal = product.discount > 0
				? subtotalWithoutDiscount - discountAmount
        : subtotalWithoutDiscount;

      subtotal_usd += product.subtotal;
    }

    const discountAmount = form.discount > 0
      ? (subtotal_usd * form.discount) / 100
      : 0;

    subtotal_usd = form.discount > 0
      ? subtotal_usd - discountAmount
      : subtotal_usd;

    subtotal_bs = parse(subtotal_usd * exchangeRate);
    total_usd = parse(subtotal_usd);
    total_bs = parse(total_usd * exchangeRate);
    left_usd = parse(subtotal_usd - paid_usd);
    left_bs = parse(left_usd * exchangeRate);

    if (left_usd < 0) {
      change_usd = parse(left_usd * -1);
      change_bs = parse(change_usd * exchangeRate);
      left_usd = parse(0);
      left_bs = parse(0);
    }

    await this.setState({
      form: {
        ...form,
        change: change_usd,
        details: products,
      },
      totals: {
        subtotal_usd,
        subtotal_bs,
        paid_usd,
        paid_bs,
        left_usd,
        left_bs,
        total_bs,
        total_usd,
        change_bs,
        change_usd,
      }
    })
  }

  debouncingSearchClient = async (search) => {
    if (this.debounce.current) clearTimeout(this.debounce.current);

    await this.change(undefined, 'client_id');
    await this.change(search, 'search');
    this.debounce.current = setTimeout(async () => {
      this.getClients();
    }, 1000);
  }

  onSelectClient = async (id) => {
    const client = this.state.clients.find(client => client.id === id);
    await this.change(id, 'client_id');
    await this.change(client, 'client');
  }

  toggleClientModal = () => {
    this.setState(state => ({ visible_client: !state.visible_client }));
  }

  toggleProductsModal = () => {
    if (!this.state.exchangeRate)
      return Globals.showError('Debe establecer una tasa de cambio para continuar');
    this.setState(state => ({ visible_products: !state.visible_products }));
  }

  addProduct = async (product, warehouse_id) => {
    this.setState(state => ({
      form: {
        ...state.form,
        warehouse_id: warehouse_id,
      }
    }));

    let productIndex;
    let products = Globals.copy(this.state.form.details);

    productIndex = product.type === Constants.PRODUCTS_TYPE.SIZE_COLOR
      ? products.findIndex(p => p.id === product.id && p.size_id === product.size_id && p.color_id === product.color_id)
      : products.findIndex(p => p.id === product.id);

    if (productIndex >= 0) {
      const newQuantity = products[productIndex].quantity + product.quantity;
      if (newQuantity > products[productIndex].stock)
        return Globals.showError(`No hay suficiente stock (${ products[productIndex].stock } disponibles)`)

      products[productIndex].quantity = newQuantity;
      products[productIndex].subtotal += product.subtotal;

    } else {
      products = [ ...products, product ];
    }

    await this.change(products, 'details');
    await this.setTotals();
  }

  removeProduct = async (index) => {
    let products = this.state.form.details.filter((_, idx) => idx !== index);
    await this.change(products, 'details');
    await this.setTotals();
  }

  oneMoreProduct = async (index) => {
    const products = Globals.copy(this.state.form.details);
    const product = products[index]
    let qty = Number(products[index].quantity)
    let newQuantity = qty + 1
    if (product.decimal_stock == Constants.DECIMAL_STOCK.YES) {
      newQuantity = parseFloat(qty + 1).toFixed(2)
    }

    const stockToValidate = products[index].total_quantity !== undefined ? products[index].total_quantity : products[index].stock;

    if (Number(newQuantity) > Number(stockToValidate))
      return Globals.showError(`No hay suficiente stock de ${products[index].name}`);

    products[index].quantity = newQuantity;
    await this.change(products, 'details');
    this.setTotals();
  }

  oneLessProduct = async (index) => {
    const products = Globals.copy(this.state.form.details);
    const product = products[index]
    let qty = Number(products[index].quantity)
    let newQuantity = qty - 1
    if (product.decimal_stock == Constants.DECIMAL_STOCK.YES) {
      newQuantity = parseFloat(qty - 1).toFixed(2)
    }

    if (newQuantity < 1) return;

    const stockToValidate = products[index].total_quantity !== undefined ? products[index].total_quantity : products[index].stock;

    if (Number(newQuantity) > Number(stockToValidate)) {
      newQuantity = stockToValidate;
      Globals.showError(`No hay suficiente stock de ${products[index].name}`);
    }

    products[index].quantity = newQuantity;
    await this.change(products, 'details');
    this.setTotals();
  }

	changeManual = async (index, quantity) => {
    const products = Globals.copy(this.state.form.details);
    let newQuantity = quantity;

    if (products[index]?.decimal_stock === Constants.DECIMAL_STOCK.NO) {
      newQuantity = newQuantity === '' ? newQuantity : parseInt(newQuantity);
    } else {
      newQuantity = newQuantity.replace(/,/g, '.');
    }

    products[index].quantity = newQuantity;
    await this.change(products, 'details');
    this.setTotals();
	}

  addPaymentMethod = (id) => {
    const method = this.state.methods.find(m => m.id === id);
    const isLocalCurrency = Constants.LOCAL_CURRENCIES.includes(method.id);
    const newMethod = {
      id,
      isLocalCurrency,
      amount: '',
      amount_bs: '',
      name: method.name,
    };

    this.setState(state => ({
      form: {
        ...state.form,
        methods_selected: [
          ...state.form.methods_selected,
          newMethod,
        ]
      }
    }));
  }

  changePaymentAmount = async (index, amount, isLocalCurrency = false) => {
    const payments = Globals.copy(this.state.form.methods_selected);

    if (isLocalCurrency) {
      payments[index].amount = parse(parse(amount || 0) / this.state.exchangeRate);
      payments[index].amount_bs = amount;
    } else {
      payments[index].amount = amount;
      payments[index].amount_bs = parse(parse(amount || 0) * this.state.exchangeRate);
    }

    await this.setState(state => ({
      form: {
        ...state.form,
        methods_selected: payments,
      }
    }));
    this.setTotals();
  }

  removePaymentMethod = async (index) => {
    const methods_selected = this.state.form.methods_selected.filter((_, idx) => idx !== index);

    await this.setState(state => ({
      form: {
        ...state.form,
        methods_selected,
      }
    }));
    this.setTotals();
  }

  isValidForm = () => {
    const onError = (msg) => {
      this.setState({ isSubmittedForm: false });
      Globals.showError(msg);
      return false;
    }

    this.setState({ isSubmittedForm: true });
    const { form, totals } = this.state;

    if (
      form.details.some(
        (i) =>
          (!i.quantity || i.quantity < 1) && i.decimal_stock == Constants.DECIMAL_STOCK.NO
      )
    ) 
    {
      return onError("Debe seleccionar la cantidad de todos los productos");
    }

		if (form.details.some(i => !i.sale_price))
			return onError('Debe seleccionar el precio de todos los productos');

      if (form.methods_selected.some(i => i.amount <= 0))
        return onError('Debe ingresar el monto de cada método de pago');

    if (form.status_payment === Constants.PAYMENT_TYPES.TO_PAY) {
      if (form.credit_days <= 0)
        return onError('Debe ingresar los días de crédito');

      if (parse(form.initial_payment) >= totals.total_usd)
        return onError('El pago inicial no puede ser igual o mayor al monto total. Este es un pedido por cobrar');

      if (parse(form.initial_payment) > 0 && !form.methods_selected.length)
        return onError('Debe seleccionar al menos 1 método de pago');

      if (totals.paid_usd > 0 && totals.paid_usd !== parse(form.initial_payment))
        return onError('La suma de los métodos de pago debe ser igual al pago inicial');

      if (!form.credit_days) {
        return onError('Debe indicar la cantidad de días de crédito');
      }

    } else {
      const tolerance = 0.0001; // Define la tolerancia para la comparación

      if (Math.abs(totals.paid_usd - totals.total_usd) > tolerance) {
        console.log('totals.paid_usd: ', totals.paid_usd);
        console.log('totals.total_usd: ', totals.total_usd);
        return onError('La suma de los métodos de pago debe ser igual al total');
      }
    }

		if (form.discount !== '' && form.discount < 0)
			return onError('El porcentaje de descuento general no puede ser negativo');

		if (form.discount !== '' && form.discount > 100)
			return onError('El porcentaje de descuento general no puede ser mayor a 100%');

    return true;
  }

  submit = async () => {
    if (!this.isValidForm()) return;

    const { form, totals } = this.state;

    const data = {
      ...form,
      currency: Number(form.currency),
      details: JSON.stringify(form.details),
      methods_selected: JSON.stringify(form.methods_selected),
      initial_payment: form.status_payment === Constants.PAYMENT_TYPES.PAID ? null : form.initial_payment,
      credit_days: form.status_payment === Constants.PAYMENT_TYPES.PAID ? null : parseInt(form.credit_days),
      discount: form.discount || 0,
      total: totals.total_usd,
    };

    try {
      const res = await axios.post('web/admin/orders/store', data);
      
      //Mensaje de error
      if(res.data?.result == false){
        Globals.showError(res.data?.error ?? 'El cliente ya ha alcanzado su limite de crédito permitido');
      }

      if (res.data?.url) {
        Globals.showSuccess('Se ha creado su pedido correctamente');
        this.openPrintable(res.data.url);
        //Imprimo la copia si existe
        if (res.data?.url_copy !== '') this.openPrintable(res.data.url_copy);
      }
      else if(res.data?.print_note_fiscal){
        //Si tiene seleccionado impirmir la nota en la impresora fiscal
        Globals.showSuccess('Se ha creado su pedido correctamente. Espere la nota de entrega');
        const order_id = res.data?.print_note_fiscal
        const res_fiscal = await axios.post('admin/orders/no-fiscal-bill', {user_id: this.props.user.id, order_id: order_id});
        if(res_fiscal.data?.result){
          Globals.showSuccess('Nota de entrega en proceso...');
        }
        else{
          Globals.showError('Ocurrió un problema al imprimir la nota de entrega');
        }
      }
      this.resetForm();
      this.goBack();

    } catch (error) {
      if(error.response.data.error){
        Globals.showError(error.response.data.error);
      }
      else{
        Globals.showError(error);
      }
      console.log('NewRequest -> submit -> catch:', error)
    }

    this.setState({ isSubmittedForm: false });
  }

  openPrintable = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  }

  checkedChange = (newValue) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        is_copy: newValue
      }
    }));
  }

  render() {

    const { form, clients, methods, sellers, isSubmittedForm, step, totals, visible_client, visible_products, zones, clientOrdersToPay, isLoadingWarehouses, client_categories, } = this.state;
    
    const total_client_to_pay = clientOrdersToPay?.to_pay_orders?.reduce((accumulator, item) => accumulator + item.to_pay, 0);
    
    const filteredPaymentMethods = methods.filter(m => {
      return !this.state.form.methods_selected.some(ms => ms.id === m.id);
    });

    const warehouse_principal = this.props.user.warehouse_principal

    const filtered_clientCategories = client_categories.filter(Item => {
      return Item.id && Item.id > 0;
    });

    if (step === STEP.PAYMENT) return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={() => this.goBack()} />}
      >
        <div id="report-sale-create">
          <main>
            <section className="payment">
              <section className="payment-type">
                <Button
                  block
                  color={form.status_payment === Constants.PAYMENT_TYPES.PAID ? ' ':'white'}
                  onClick={() => this.change(Constants.PAYMENT_TYPES.PAID, 'status_payment')}
                >
                  PAGADO
                </Button>

                <Button
                  block
                  color={form.status_payment === Constants.PAYMENT_TYPES.TO_PAY ? ' ':'white'}
                  onClick={() => this.change(Constants.PAYMENT_TYPES.TO_PAY, 'status_payment')}
                >
                  POR PAGAR
                </Button>
              </section>

              <section className="inputs">
                <div className="form-group">
                  <label className="material">Descuento (%)</label>
                  <NumberFormat
                    value={form.discount}
                    className="form-control material"
                    placeholder="Opcional"
                    allowNegative={false}
                    decimalScale={2}
                    onValueChange={async (v) => {
                      await this.change(v.value, 'discount');
                      this.setTotals();
                    }}
                  />
                </div>

                {form.status_payment === Constants.PAYMENT_TYPES.TO_PAY && (
                  <>
                    <div className="form-group">
                      <label className="material">Pago inicial</label>
                      <NumberFormat
                        value={form.initial_payment}
                        className="form-control material"
                        placeholder="Opcional"
                        allowNegative={false}
                        decimalScale={2}
                        onValueChange={v => this.change(v.value, 'initial_payment')}
                      />
                    </div>

                    <div className="form-group">
                      <label className="material">Días de crédito</label>
                      <NumberFormat
                        value={form.credit_days}
                        className="form-control material"
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={v => this.change(v.value, 'credit_days')}
                      />
                    </div>
                  </>
                )}
              </section>

              <div className="tables">
                <section className="table-wrapper payment-methods">
                  <table>
                    <thead className="bg-dark">
                      <tr>
                        <th>Moneda</th>
                        <th>Monto $</th>
                        <th>Monto {Globals.getLocalCurrencySymbol(this.props.user)}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {form.methods_selected.map((item, index) => (
                        <PaymentItem
                          key={`payment-${ index }`}
                          item={item}
                          onChange={(value, isLocalCurrency = false) => this.changePaymentAmount(index, value, isLocalCurrency)}
                          onRemove={() => this.removePaymentMethod(index)}
                        />
                      ))}

                      <tr>
                        <td colSpan={6}>
                          <Select
                            value=""
                            caret
                            options={filteredPaymentMethods.map(m => ({ value: m.id, label: m.name }))}
                            onChange={e => this.addPaymentMethod(Number(e.target.value))}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <div>
                  <Button className="btn-dark" onClick={() => this.changeTab(STEP.PRODUCTS)}>
                    REGRESAR
                  </Button>
                </div>
              </div>
            </section>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Totals
                totals={totals}
                isPaymentStep
                isSubmittedForm={isSubmittedForm}
                form={form}
                onChange={(value, target) => this.change(value, target)}
                onSubmit={() => this.submit()}
              />
              <div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '8rem' }}>
                <input
                  id="photo-check"
                  type="checkbox"
                  checked={this.state.form.is_copy}
                  style={{ marginRight: '0.5rem' }}
                  onChange={() => this.checkedChange(!this.state.form.is_copy)}
                />
                <label className="" htmlFor="photo-check" style={{ fontSize: 16, marginBottom: 0 }}>
                  Imprimir copia
                </label>
              </div>
            </div>
          </main>
        </div>
      </Menu>
    );

    return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={() => this.goBack()} />}
      >
        <Modal
          className="modal-report-sale-products"
          title="Productos"
          onClose={this.toggleProductsModal}
          visible={visible_products}
        >
          <ModalProducts
            onSelectProduct={this.addProduct}
            onClose={this.toggleProductsModal}
            warehouses={this.state.warehouses}
            formatWarehouses={this.state.formatWarehouses}
          />
        </Modal>

        <Modal
          size="lg"
          className="modal-report-sale-client"
          title="Registrar cliente"
          onClose={this.toggleClientModal}
          visible={visible_client}
        >
          <CreateEditClient
            user={this.props.user}
            createFromPOS={true}
            zones={zones}
            onSuccess={client => this.setState({ created_client: client })}
            onClose={(reload = false) => {
              if (reload) this.getClients();
              this.toggleClientModal();
            }}
            client_categories={filtered_clientCategories}
            sellers={sellers}
          />
        </Modal>

        <Modal
          className="modal-report-sale-client"
          title="CUENTAS PENDIENTES CLIENTE"
          onClose={() => this.setState({ clientOrdersToPay: null })}
          visible={clientOrdersToPay}
        >
          <p>Cliente: <b>{ clientOrdersToPay?.name }</b></p>
          <table border={1} style={{ border: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th>Cuenta</th>
                <th>Fecha</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              {clientOrdersToPay?.to_pay_orders?.map((item, index) => (
                <tr key={index} style={{ textAlign: 'right' }}>
                  <td style={{ textAlign: 'center' }}>{ item?.number_format }</td>
                  <td style={{ textAlign: 'center' }}> { item?.created_es } </td>                
                  <td style={{ textAlign: 'right' }}>${ Globals.formatMiles(item?.to_pay) }</td>
                </tr>
              ))}
              <tr style={{ textAlign: 'right' }}>
                <td colSpan={2}>
                  <b>Total ctas. por cobrar:</b>
                </td>
                <td>
                  <b>$ { Globals.formatMiles(total_client_to_pay) }</b>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal>

        <div id="report-sale-create">
          <div className="filters-row">
            <section className="filters">
              <div className="row">
                <div className="col-4">
                  <SelectActions
                    label="Nombre del cliente"
                    placeholder="Nombre del Cliente o Rif"
                    isSearchable
                    actionOptions={[
                      {
                        onClick: () => this.toggleClientModal(),
                        content: (
                          <div style={{ color: '#3A7FC2' }}>
                            <span>Registrar nuevo cliente</span>
                            <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                          </div>
                        ),
                      }
                    ]}
                    options={clients.map(i => ({
                      value: i.id,
                      label: i.label,
                      actions: [],
                    }))}
                    value={form.client_id}
                    onChange={this.onSelectClient}
                    onInputChange={this.debouncingSearchClient}
                  />
                </div>

                <div className="col-4">
                  <SelectActions
                    label="Vendedor (Opcional)"
                    placeholder="Nombre del Vendedor"
                    isSearchable
                    options={sellers.map(i => ({
                      value: i.id,
                      label: i.name,
                      actions: [],
                    }))}
                    value={form.seller_id}
                    onChange={async (id) => {
                      //await this.change(id, 'user_id');
                      await this.change(id, 'seller_id');
                    }}
                  />
                </div>
              </div>

              <div>
                {form.client_id && (
                  <div className="client-address">
                    <img src={LocationPinIcon} alt="Dirección del cliente" />
                    <p>{ form.client?.address }</p>
                  </div>
                )}
              </div>

              <div>
                {(form.client_id && form.client?.to_pay_orders?.length > 0) && (
                  <div className="client-address">
                    <p style={{ color: 'red' }}>{"Cliente posee cuenta por cobrar pendiente,"}</p>
                    &nbsp;
                    <span
                      style={{ color: 'red' }}
                      className="clickable"
                      onClick={() => this.setState({ clientOrdersToPay: form.client })}
                    >
                      {"ver detalles"}
                    </span>
                  </div>
                )}
              </div>
            </section>

            <Totals 
              totals={totals} 
              form={form} 
            />
          </div>

          <section className="table-wrapper">
            <div className="table-header">
              <Button onClick={() => this.toggleProductsModal()} disabled={isLoadingWarehouses}>
                <div className="loader-wrapper">
                  {isLoadingWarehouses && <div class="loader" />}
                  <span>AGREGAR PRODUCTO</span>
                </div>
              </Button>

              {!!form.details.length && (
                <Button color="green" onClick={() => this.goToPaymentStep()}>
                  SIGUIENTE
                </Button>
              )}
            </div>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Código</th>
                  <th>Descripción</th>
                  <th>Stock</th>
                  <th>Cantidad{'\n'}solicitada</th>
                  <th>Precio</th>
                  <th>Descuento (%)</th>
                  <th>Subtotal</th>
                  <th className="actions"></th>
                </tr>
              </thead>
              <tbody>
                {form.details.map((item, index) => (
                  <tr key={index.toString()}>
                    <td>{ index + 1 }</td>
                    <td>{ item.code }</td>
                    <td>{ item.name }</td>
                    <td>
                      { 
                        item.warehouse_id == warehouse_principal ?
                          item.stock
                        :
                          item.total_quantity ? 
                            item.total_quantity
                          :
                            item.stock 
                      }
                    </td>
                    <td className="quantity">
                      <Button small onClick={() => this.oneLessProduct(index)}>
                        <i className="fa fa-minus" />
                      </Button>
                      <input
                        value={item.quantity}
                        onChange={(e) => {
                          if (item?.decimal_stock === Constants.DECIMAL_STOCK.NO) {
                            if (Globals.validateInteger(e.target.value) || e.target.value === '') {
                              if(Number(e.target.value) > item.stock) return;
                              this.changeManual(index, e.target.value);
                            }
                          } else {
                            if (Number(e.target.value) > item.stock) return;
                            this.changeManual(index, e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            this.changeManual(index, '0');
                          }
                        }}
                      />
                      <Button small onClick={() => this.oneMoreProduct(index)}>
                        <i className="fa fa-plus" />
                      </Button>
                    </td>
                    <td>{ item.sale_price }</td>
                    <td>{ item.discount || '--' }</td>
                    <td>{ Globals.formatMiles(parseFloat(item.subtotal).toFixed(2)) }</td>
                    <td>
                      <img
                        src={CloseIcon}
                        className="btn-remove"
                        title="Remover producto"
                        alt="Remover producto"
                        onClick={() => this.removeProduct(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </Menu>
    )
  }
}

const BackButton = ({ onClick }) => (
  <div className="back-button">
    <img src={ LeftArrowIcon } onClick={() => onClick()} alt="Regresar" />
  </div>
)

const Totals = ({ totals, isPaymentStep, onSubmit, isSubmittedForm = false, form, onChange }) => (
  <div className="totals-wrapper">
    {!!isPaymentStep && (
      <ul className="totals inverted">
        <li>
          <span>Subtotal</span>
          <span className="amount">{ format(totals.subtotal_bs) }</span>
        </li>
        <li>
          <span>Abonado</span>
          <span className="amount">{ format(totals.paid_bs) }</span>
        </li>
        <li>
          <span>Restante</span>
          <span className="amount">{ format(totals.left_bs) }</span>
        </li>
      </ul>
    )}

    <ul className="totals">
      {!isPaymentStep && (
        <li>
          <span>Subtotal</span>
          <span className="amount">{ format(totals.subtotal_bs) }</span>
        </li>
      )}

      <li>
        <span>Total ({Globals.getLocalCurrencySymbol(form.user)})</span>
        <div>
          <div className="amount bold red" style={{ fontSize: 22 }}>
            { format(totals.total_bs) }
          </div>
          <div className="amount bold green" style={{ fontSize: 18 }}>
            $ { format(totals.total_usd) }
          </div>
        </div>
      </li>

      {!!isPaymentStep && (
        <>
          <li>
            <span>Cambio {Globals.getLocalCurrencySymbol(form.user)}</span>
            <div>
              <div className="amount" style={{ fontSize: 16 }}>
                { format(totals.change_bs) }
              </div>
              <div className="amount" style={{ fontSize: 14 }}>
                $ { format(totals.change_usd) }
              </div>
            </div>
          </li>
          <li>
            <span>Moneda</span>
            <div>
              <select
                value={form.currency}
                onChange={e => onChange(e.target.value,'currency')}
              >
                <option value={Constants.CURRENCIES.DOLARES} selected>$ (USD)</option>
                <option value={Constants.CURRENCIES.BOLIVARES}>{Globals.getLocalCurrencySymbol(form.user)} ({Globals.getLocalCurrencyName(form.user)})</option>
              </select>
            </div>
          </li>
          <li>
            <Button
              color="green"
              block
              onClick={() => onSubmit()}
              disabled={isSubmittedForm}
            >
              {isSubmittedForm
                ? <Spinner animation="border" size="sm" role="status" />
                : <span>FINALIZAR PEDIDO</span>
              }
            </Button>
          </li>
        </>
      )}
    </ul>
  </div>
);

const PaymentItem = ({ item, index, onChange, onRemove }) => {

  const [bsHasChaged, setBsHasChaged] = useState(false);

  return (
    <tr key={`payment-${ index }`}>
      <td>{ item.name }</td>
      <td>
        <NumberFormat
          value={item.amount}
          allowNegative={false}
          decimalScale={2}
          onFocus={() => setBsHasChaged(false)}
          onValueChange={v => !bsHasChaged && onChange(v.value)}
        />
      </td>
      <td>
        {item.isLocalCurrency ? (
          <NumberFormat
            value={item.amount_bs}
            allowNegative={false}
            decimalScale={2}
            onFocus={() => setBsHasChaged(true)}
            onValueChange={v => bsHasChaged && onChange(v.value, true)}
          />
        ) : (
          format(parse(Number(item.amount_bs)))
        )}
      </td>
      <td>
        <img
          src={CloseIcon}
          className="btn-remove"
          title="Remover método de pago"
          alt="Remover método de pago"
          onClick={() => onRemove()}
        />
      </td>
    </tr>
  )
}

export default connect(state => ({
  user: state.user,
  currency: state.currency,
}))(NewRequest)