import React from "react";
import { connect } from "react-redux";
import { emit } from "jetemit";
import { Select, Input } from "../../components";
import { axios, Globals, Constants, ENV } from "../../utils";
import PrintStyle1 from '../../assets/img/print-style-1.jpg';
import PrintStyle2 from '../../assets/img/print-style-2.jpg';
import CloseIcon from '../../assets/icons/close-black.png';
import $ from "jquery";


const FORMATS = [
  { value: Constants.PRINTER.FORMAT.CARTA, label: 'Carta' },
  { value: Constants.PRINTER.FORMAT.MEDIA_CARTA, label: 'Media Carta' },
  { value: Constants.PRINTER.FORMAT.TICKET_58, label: 'Tickera 58mm' },
  { value: Constants.PRINTER.FORMAT.TICKET, label: 'Tickera 80mm' },
];

const TICKET_STYLES = [
  { value: Constants.PRINTER.TICKET_STYLE.STYLE_ONE, label: 'Estilo 1' },
  { value: Constants.PRINTER.TICKET_STYLE.STYLE_TWO, label: 'Estilo 2' }
];

const PRINTERS = [
  /* Para PROD solo opcion Impresora (estándar o tickera) */
  { value: Constants.PRINTER.TYPE.FISCAL, label: 'Máquina Fiscal', status: 1 },
  { value: Constants.PRINTER.TYPE.STANDARD, label: 'Impresora (estándar o tickera)', status: 1 },
  { value: Constants.PRINTER.TYPE.BOTH, label: 'Ambos (Máquina fiscal y estándar)', status: 1 },
  { value: Constants.PRINTER.TYPE.FREE_FORM, label: 'Impresión Fiscal Forma Libre (Carta)', status: 1 },
];

const PRINT_BY = [
  // { value: Constants.PRINTER.PRINT_NOTE.NONE, label: 'No emitir notas de entrega' },
  { value: Constants.PRINTER.PRINT_NOTE.FISCAL, label: 'Emitir por Maquina Fiscal' },
  { value: Constants.PRINTER.PRINT_NOTE.STANDARD, label: 'Impresora Estandar o tickera' },
];

class PrinterType extends React.Component {
  state = {
    header: ["Monto", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    conversion: null,
    view: false,
    form: {
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      user_id: this.props.user.id,
      type: '',
      format: Constants.PRINTER.FORMAT.TICKET,
      ticket_style: '',
      print_note: '',
      print_code: '',
      print_serial: '',
      id: null,
      ticket_style: Constants.PRINTER.TICKET_STYLE.STYLE_ONE,
    },
    isVenezuela: Globals.getCountry(this.props.user)?.id === Constants.COUNTRIES.VENEZUELA,
    printers: [],
    formats: FORMATS,
    TICKET_STYLES,
    printBy: [],
    showFullview: false,
  };
  componentDidMount() {
    this.load();
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);

    this.loadPrinters();
    this.loadPrintBy();
  }
  componentDidUpdate() {
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  loadPrinters = () => {
    let printers = []
    if(ENV.mode === 3){
      const PRINTERS = [
        /* Para PROD solo opcion Impresora (estándar o tickera) */
        { value: Constants.PRINTER.TYPE.FISCAL, label: 'Máquina Fiscal (Contactar Soporte)', status: 0 },
        { value: Constants.PRINTER.TYPE.STANDARD, label: 'Impresora (estándar o tickera)', status: 1 },
        { value: Constants.PRINTER.TYPE.BOTH, label: 'Ambos (Máquina fiscal y estándar) (Contactar Soporte)', status: 0 },
        { value: Constants.PRINTER.TYPE.FREE_FORM, label: 'Impresión Fiscal Forma Libre (Carta)', status: 1 },
      ];
      printers = PRINTERS.filter(p => {
        if (this.state.isVenezuela) return true;
        return ![Constants.PRINTER.TYPE.FISCAL, Constants.PRINTER.TYPE.BOTH].includes(p.value);
      });
    }
    else{
      printers = PRINTERS.filter(p => {
        if (this.state.isVenezuela) return true;
        return ![Constants.PRINTER.TYPE.FISCAL, Constants.PRINTER.TYPE.BOTH].includes(p.value);
      });
    }

    this.setState({ printers });
  }
  loadPrintBy = () => {
    const printBy = PRINT_BY.filter(p => {
      if (this.state.isVenezuela) return true;
      return p.value !== Constants.PRINTER.PRINT_NOTE.FISCAL;
    });

    this.setState({ printBy });
  }
  load = () => {
    // Globals.setLoading();
    axios
      .post("web/admin/printer-type", this.state.form)
      .then(async res => {
        if (res.data.printer_type) {
          await this.setState({
            data: res.data.printer_type,
            form: {
              ...this.state.form,
              id: res.data?.printer_type?.id,
              type: (this.state.printers.length === 1 && this.state.printers[0].id != res.data?.printer_type?.type) ? '' : res.data?.printer_type?.type,
              format: res.data?.printer_type?.format,
              ticket_style: res.data?.printer_type?.ticket_style,
              print_note: res.data?.printer_type?.print_note,
              print_code: res.data?.printer_type?.print_code,
              print_serial: res.data?.printer_type?.print_serial ?? '',
            },
          });
        }
      })
      .catch(err => {
        // Globals.showError();
      })
      .then(() => {
        // Globals.quitLoading();
      });
  };

  change = async (emitter) => {
    const { value, name } = emitter.target;
    console.log('value: ', value)
    console.log('name: ', name)
    const form = Globals.copy(this.state.form);

    if (name === 'type') form.print_note = '';
    if (['type','print_note'].includes(name)) form.format = '';
    form[name] = value;

    await this.setState({ form });
  };
  submit = async () => {
    let param = Globals.copy(this.state.form)
    if (param.type == Constants.PRINTER.TYPE.FISCAL && param.print_note == Constants.PRINTER.PRINT_NOTE.FISCAL) {
      param.format = Constants.PRINTER.FORMAT.CARTA;
    }
    axios.post('web/admin/printer-type/edit', param)
      .then(res => {
        this.props.dispatch({ type: 'SET_PRINTER', payload: res.data.printer_type });
        // Globals.showSuccess('Tipo de impresión registrada con exito');
        emit('update-sidebar', {});
      })
      .catch(err => {
        // Globals.showError();
      })
      .then(() => {
        this.load()
      });
  }
  
  showFullview = (img) => {
    this.setState({ showFullview: img });
  }

  render() {

    const showType = Number(this.state.form.type) === Constants.PRINTER.TYPE.FISCAL;
    let showFormat = Number(this.state.form.print_note) === Constants.PRINTER.PRINT_NOTE.STANDARD || Number(this.state.form.type) !== Constants.PRINTER.TYPE.FISCAL;
    const showStyle = Number(this.state.form.format) === Constants.PRINTER.FORMAT.TICKET;

    if(Number(this.state.form.type) === Constants.PRINTER.TYPE.FREE_FORM){
      showFormat = false;
    }

    if (!this.props.visible) {
      return null;
    }

    return (
      // <Menu history={this.props.history}>
      <div id="printer">
        {this.state.showFullview && (
          <div className="style-fullview">
            <div className="close-fullview">
              <img src={CloseIcon} alt="Cerrar" onClick={() => this.showFullview(false)} />
            </div>
            <div className="img-wrapper">
              <img src={this.state.showFullview} alt="Estilo 1" />
            </div>
          </div>
        )}

        <section className="printer-form">
          {/*<div className="conversion-header text-center">Impresora por defecto en notas de entrega</div>*/}
          <div className="conversion-form">
            <div className="label">Tipo de Impresora:</div>
            <div>
              <Select
                color="white"
                name="type"
                labelClass="material"
                className="material"
                options={this.state.printers}
                value={this.state.form.type}
                onChange={this.change}
              />
            </div>
          </div>

          {/* SI ES IMPRESORA FISCAL LE MUESTRO EL SERIAL DE IMPRESORA */}
          {(showType) && (
            <div className="conversion-form">
              <div className="label">Serial de impresora:</div> 
              <div>
                <Input
                  type="text"
                  color="white"
                  name="print_serial"
                  labelClass="material"
                  className="material"
                  value={this.state.form.print_serial}
                  onChange={this.change}
                />
              </div>
            </div>
          )}

          {showType && (
            <div className="conversion-form">
              <div className="label">Notas de Entrega:</div>
              <div>
                <Select
                  color="white"
                  name="print_note"
                  labelClass="material"
                  className="material"
                  options={this.state.printBy}
                  value={this.state.form.print_note}
                  onChange={this.change}
                />
              </div>
            </div>
          )}

          {showFormat && (
            <div className="conversion-form">
              <div className="label">Formato:</div>
              <div>
                <Select
                  color="white"
                  name="format"
                  labelClass="material"
                  className="material"
                  options={this.state.formats}
                  value={this.state.form.format}
                  onChange={this.change}
                />
              </div>
            </div>
          )}

          {(showFormat && showStyle) && (
            <div className="conversion-form">
              <div className="label">Estilo:</div>
              <div>
                <Select
                  color="white"
                  name="ticket_style"
                  labelClass="material"
                  className="material"
                  options={this.state.TICKET_STYLES}
                  value={this.state.form.ticket_style}
                  onChange={this.change}
                  disabledFirst={false}
                />
              </div>
            </div>
          )}

          {/* SI ES IMPRESORA FISCAL LE MUESTRO EL CODIGO DE IMPRESORA QUE USARA EN ELECTRON */}
          {(showType && (this.state.form.print_code != '' && this.state.form.print_code != null)) && (
            <div className="conversion-form">
              <div className="label">Código de impresora:</div> 
              <div>
                <Input
                  color="white"
                  name="print_code"
                  labelClass="material"
                  className="material"
                  value={this.state.form.print_code}
                  disabled={true}
                />
              </div>
            </div>
          )}

          {/* Habilitado solo para usuarios admin */}
          {/*<div className="actions">
            <Button disabled={this.props.user.level_id !== Constants.ROLE_ADMIN} onClick={() => this.submit()}>Guardar</Button>
          </div>*/}
        </section>

        {(showFormat && showStyle) && (
          <section className="style-preview">
            {!this.state.form.ticket_style ? (
              <p>Selecciona un estilo</p>
            ) : (
              <p>Vista previa del estilo</p>
            )}
            {(Number(this.state.form.ticket_style) === Constants.PRINTER.STYLE.STYLE_ONE) && (
              <img src={PrintStyle1} alt="Estilo 1" onClick={() => this.showFullview(PrintStyle1)} />
            )}
            {(Number(this.state.form.ticket_style) === Constants.PRINTER.STYLE.STYLE_TWO) && (
              <img src={PrintStyle2} alt="Estilo 2" onClick={() => this.showFullview(PrintStyle2)} />
            )}
          </section>
        )}
      </div>
      // </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
},null,null,{ forwardRef: true })(PrinterType);
