import HoldOn from "react-hold-on";
import Swal from "sweetalert2";
import $ from "jquery";
import Colors from "./colors";
import { Constants, ENV } from ".";
import { Enterprise } from "../services";

const rifValidPreffixes = ['V','J','E','P','G'];
const rifValidItems = [...rifValidPreffixes,'0','1','2','3','4','5','6','7','8','9'];

class Globals {
  setLoading = () => {
    HoldOn.open({
      theme: "sk-bounce"
    });
  };

  quitLoading = () => {
    HoldOn.close();
  };

  soloNumeros = e => {
    var key = window.Event ? e.which : e.keyCode;
    var condition = key >= 48 && key <= 57;
    if (!condition) {
      e.preventDefault();
    }
  };

  soloRifDocument = e => {
    const key = e.which || e.keyCode;
    const allowedCharacters = [86, 74, 69, 45, 47]; // Códigos de caracteres permitidos: v, j, e, -, /
    const isNumber = key >= 48 && key <= 57;
    const isAllowedCharacter = allowedCharacters.includes(key);
    const character = String.fromCharCode(key).toLowerCase();
    
    if (!isNumber && !isAllowedCharacter && !['v', 'j', 'e', '-', '/'].includes(character)) {
      e.preventDefault();
    }
  };
  

  isValidRifPreffix = (value) => {
    return rifValidPreffixes.some(letter => String(value).toLowerCase().startsWith(letter.toLowerCase()));
  }

  onlyRifChars = (e) => {
    const key = window.Event ? e.which : e.keyCode;
    if (!rifValidItems.some(letter => [letter.charCodeAt(), letter.toLowerCase().charCodeAt()].includes(key)))
      e.preventDefault();
  }

  formatMiles = (n, decimals = true) => {
    var c, d, t, j, s, i;
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? "," : d;
    t = t === undefined ? "." : t;
    s = n < 0 ? "-" : "";
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
    j = (j = i.length) > 3 ? j % 3 : 0;

    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
        : "")
    );
  };

  hideTooltip = async () => {
    await setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  };

  showSuccess = message => {
    Swal.fire({
      title: "",
      text: message,
      type: "success",
      showCancelButton: false
    });
  };

  showWarning = message => {
    Swal.fire({
      title: "",
      text: message,
      type: "warning",
      showCancelButton: false
    });
  };

  showInformation = message => {
    Swal.fire({
      title: "",
      text: message,
      type: "info",
      showCancelButton: false
    });
  };

  // showError = message => {
  //   Swal.fire({
  //     title: "",
  //     text: message ? message : "Se ha producido un error",
  //     type: "error",
  //     showCancelButton: false
  //   });
  // };
  showError = message => {
    Swal.fire({
      title: "",
      html: message ? message.replace(/\n/g, '<br>') : "Se ha producido un error",
      type: "error",
      showCancelButton: false
    });
  };

  confirm = (message, callback, title = "") => {
    Swal.fire({
      title: title,
      text: message,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar"
    }).then(async confirm => {
      await this.hideTooltip();
      if (confirm.value) {
        callback();
      }
    });
  };

  currencyOptions = (message, callback, title = "") => {
    Swal.fire({
      title: title,
      text: message,
      type: "question",
      showCancelButton: true,
      confirmButtonColor: '#3A7FC2',
      cancelButtonColor: '#3A7FC2',
      confirmButtonText: "Dolares",
      cancelButtonText: "Bolívares"
    }).then(async confirm => {
      await this.hideTooltip();
      if (confirm.value) {
        callback(Constants.CURRENCIES.DOLARES);
      }
      else{
        callback(Constants.CURRENCIES.BOLIVARES);
      }
    });
  };

  typeDownloadOptions = (message, callback, title = "") => {
    Swal.fire({
      title: title,
      text: message,
      type: "question",
      showCancelButton: true,
      confirmButtonColor: '#3A7FC2',
      cancelButtonColor: '#3A7FC2',
      confirmButtonText: "PDF",
      cancelButtonText: "Excel"
    }).then(async confirm => {
      await this.hideTooltip();
      if (confirm.value) {
        callback('pdf');
      }
      else{
        callback('excel');
      }
    });
  };

  sure = (message, callback, title = "") => {
    Swal.fire({
      title: title,
      text: message,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar"
    }).then(async confirm => {
      await this.hideTooltip();
      callback(confirm);
    });
  };

  continueOrReturn = (message, callback, title = "") => {
    Swal.fire({
      title: title,
      text: message,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Volver"
    }).then(async confirm => {
      await this.hideTooltip();
      callback(confirm);
    });
  };

  getUserId = user => {
    const { id: userId, level_id: roleId, enterprise_users: enterprise } = user;

    // return roleId === Constants.ROLE_SELLER
    //   ? userId
    //   : roleId === Constants.ROLE_SELLER
    //     ? userId
    //     : enterprise
    //       ? enterprise.enterprise_id
    //       : undefined;

    return userId
  };

  getEvaluation = roleId => {
    switch (roleId) {
      case Constants.ROLE_ADMIN:
        return false;
      case Constants.ROLE_MODERATOR:
        return false;
      case Constants.ROLE_SELLER:
        return false;
      default:
        return true;
    }
  };

  setTypeRole = user => {
    const { level_id: roleId } = user;

    switch (roleId) {
      case Constants.ROLE_ADMIN:
        return user;
      case Constants.ROLE_MODERATOR:
        return user;
      default:
        return false;
    }
  };

  getRate = user => {
    const { role } = user;

    switch (role) {
      case Constants.ROLE_SELLER:
        return (user || {}).configuration_seller
          ? user.configuration_seller
          : null;
      default:
        return null;
    }
  };

  getZones = ({ zones: zonesId, level_id: roleId }) => {
    return roleId === Constants.ROLE_SELLER
      ? zonesId.map(({ zoneId }) => zoneId)
      : [];
  };

  getStatus = (
    status,
    custom_value = null,
    custom_text = "",
    custom_color = ""
  ) => {
    // custom_color debe ser un hexadecimal, custom_text debe ser un string
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nuevo",
          color: Colors.red
        };
        break;
      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;
      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      case 3:
        respuesta = {
          text: "Eliminado",
          color: Colors.red
        };
        break;
      case custom_value:
        respuesta = {
          text: custom_text,
          color: custom_color
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  getPaymentsType = type => {
    let response = "";
    switch (type) {
      case 1:
        response = "Efectivo";
        break;
      case 2:
        response = "Debito";
        break;
      case 3:
        response = "Credito";
        break;
      case 4:
        response = "Transferencia";
        break;
      default:
        break;
    }
    return response;
  };

  getEnterpriseId = user => {
    // const enterprise_id = user
    //   ? user.role === Constants.ROLE_SELLER ||
    //     user.role === Constants.ROLE_SELLER
    //     ? user.id
    //     : user.enterprise_users.enterprise_id
    //   : "";
    const enterprise_id = user.id
    return enterprise_id;
  };

  getAssignedWarehouses = user => {
    let arr = [];
    // assigned_warehouses viene del backend cuando el usuario inicia sesion
    if (user.assigned_warehouses.length > 0) {
      arr = user.assigned_warehouses.map(Item => {
        return Item.warehouse_id;
      });
    }
    return arr;
  };

  filterWarehouseOrBranches = (arr_data, user, type) => {
    // type = branch o warehouse
    // Role = 3, Empresa
    // Role = 4, Usuario
    // Role = 5, Vendedor
    const param = type === "branch" ? "branch_id" : "warehouse_id";
    let filter = arr_data.filter(function (data) {
      return !data.deleted_at && data.status === 1;
    });
    if (user.role === 4) {
      filter = filter.filter(el => {
        return user.assigned_warehouses.some(f => {
          return parseInt(f[param]) === parseInt(el.id);
        });
      });
    } else {
      filter = filter;
    }
    return filter;
  };

  getLevel = level_id => {
    let level = ''

    switch (level_id) {
      case Constants.ROLE_ADMIN:
        level = 'Admin'
        break
      case Constants.ROLE_MANAGER:
        level = 'Gerente'
        break
      case Constants.ROLE_MODERATOR:
        level = 'Moderador'
        break
      case Constants.ROLE_SELLER:
        level = 'Vendedor'
        break
    }

    return level
  };

  limitIntLength = (amount, limit = 8) => {
    let nums = String(amount).split('.', 2);
    let value = nums[0].substring(0, limit);
    return `${value}${nums[1] ? `.${nums[1]}` : ''}`;
  }

  fromPhotos = (url) => {
    return ENV.BaseStorage + url;
  }

	validateDouble(value) {
		return /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(value);
	}

	validateInteger(value) {
		return /^\d+$/.test(value);
	}

	roundMiles = (n) => {
		var c = isNaN(c = Math.abs(c)) ? 2 : c,
			d = d == undefined ? "." : d,
			t = t == undefined ? "" : t,
			s = n < 0 ? "-" : "",
			i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j = (j = i.length) > 3 ? j % 3 : 0;

		const amount = s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");

		return parseFloat(amount);
	}

	clean = (_string) => {
    var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
    for (var i = 0; i < specialChars.length; i++) {
        _string= _string.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
    }
    _string = _string.toLowerCase();
    _string = _string.replace(/ /g,"_");
    _string = _string.replace(/á/gi,"a");
    _string = _string.replace(/é/gi,"e");
    _string = _string.replace(/í/gi,"i");
    _string = _string.replace(/ó/gi,"o");
    _string = _string.replace(/ú/gi,"u");
    _string = _string.replace(/ñ/gi,"n");
    return _string;
  }

  permissionChangeProductsPrices = (user) => {
    let change_price = false;
    if (user.level_id === Constants.ROLE_MODERATOR) {
      if (user.permissions.length > 0) {
        // Usamos.some() para verificar si algún permiso coincide con el código esperado
        if (user.permissions.some(permission => permission.code === Constants.PERMISSIONS.MODERATOR.PRODUCTS_CHANGE_PRICE)) {
          change_price = false; // El usuario tiene el permiso para cambiar precios
        } else {
          change_price = true; // El usuario NO tiene el permiso para cambiar precios
        }
      } else {
        change_price = true; // Si no hay permisos asignados, asumimos que no se puede cambiar el precio
      }
    }

    return change_price;
  }

  permissionChangeProductsQuantity = (user) => {
    let change_quantity = false;
    if (user.level_id === Constants.ROLE_MODERATOR) {
      if (user.permissions.length > 0) {
        // Usamos.some() para verificar si algún permiso coincide con el código esperado
        if (user.permissions.some(permission => permission.code === Constants.PERMISSIONS.MODERATOR.PRODUCTS_CHANGE_QUANTITY)) {
          change_quantity = false; // El usuario tiene el permiso para cambiar cantidad
        } else {
          change_quantity = true; // El usuario NO tiene el permiso para cambiar cantidad
        }
      } else {
        change_quantity = true; // Si no hay permisos asignados, asumimos que no se puede cambiar la cantidad
      }
    }

    return change_quantity;
  }

  permissionDeleteProduct = (user) => {
    let permission = false
    if(user.level_id === Constants.ROLE_MODERATOR){
      if(user.permissions.length > 0){
        if (user.permissions.filter(i => i.code === Constants.PERMISSIONS.MODERATOR.PRODUCTS_DELETE)){
          permission = false
        }
        else{
          permission = true
        }
      }
      else{
        permission = true
      }
    }

    return permission
  }

  permissionAcceptOrderPayment = (user) => {
    let flag = false;
    if (user.level_id === Constants.ROLE_MODERATOR) {
      if (user.permissions.length > 0) {
        // Usamos.some() para verificar si algún permiso coincide con el código esperado
        if (user.permissions.some(permission => permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_ACCEPT_PAYMENT)) {
          flag = false; // El usuario tiene el permiso para cambiar precios
        } else {
          flag = true; // El usuario NO tiene el permiso para cambiar precios
        }
      } else {
        flag = true; // Si no hay permisos asignados, asumimos que no se puede cambiar el precio
      }
    }

    return flag;
  }

  copy = (objectOrArray) => {
    return JSON.parse(JSON.stringify(objectOrArray));
  }

  copyToClipboard = async (content) => {
    const textArea = document.createElement("textarea");
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(textArea);
  }

  toParams = (obj) => {
    let str = '';
    for (let key in obj) {
      if (str !== '') str += '&';
      if (typeof obj[key] === 'boolean') obj[key] = Number(obj[key]);
      str += key + '=' + obj[key];
    }
    return '?' + str;
  }

  getCountry = (user) => {
    return user?.admin?.country || user?.country
  }

  getLocalCurrencyName = (user) => {
    const country = user?.admin?.country || user?.country

    return country.currency_name
  }

  getLocalCurrencySymbol= (user) => {
    const country = user?.admin?.country || user?.country

    return country.currency_symbol
  }

  getUserCountry = (user) => {
		if (user.level_id === Constants.ROLE_ADMIN)
			return user?.country?.id;
		return user?.admin?.country?.id;
	}

	canIncludeTaxes = (user) => {
		const countries = [
			Constants.COUNTRIES.VENEZUELA,
			Constants.COUNTRIES.OTHER,
		];

		return countries.includes(this.getUserCountry(user));
	}

  calculateTotalDistance = (details) => {
    if (!details || !details.length) return '0.00 km'; // Cambiamos '0 m' por '0.00 km'
  
    const totalMeters = details.reduce((total, detail) => {
      if (!detail.km_traveled) return total;
      const [valueStr, unit] = detail.km_traveled.split(' ');
      const value = parseFloat(valueStr) || 0;
  
      if (unit === 'km') return total + value * 1000;
      if (unit === 'm') return total + value;
      return total;
    }, 0);
  
    // Convertimos siempre a kilómetros con 2 decimales
    return `${(totalMeters / 1000).toFixed(2)} km`;
  };

  calculateTotalTime = (details) => {
    if (!details || !details.length) return '0 min';

    const totalMinutes = details.reduce((total, detail) => {
      if (!detail.working_time) return total;
      const [valueStr, unit] = detail.working_time.split(' ');
      const value = parseFloat(valueStr) || 0;

      if (unit === 'horas') return total + value * 60;
      if (unit === 'minutos') return total + value;
      return total;
    }, 0);

    return totalMinutes >= 60
      ? `${(totalMinutes / 60).toFixed(2)} horas`
      : `${totalMinutes} minutos`;
  };
}

export default new Globals();
