import React, { Component } from "react";
import { connect } from "react-redux";
import { Pie } from "react-chartjs-2";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Menu from "../menu";
import { DatePicker, Button } from "../../components";
import { Constants, Globals, axios } from "../../utils";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// const COLORS = ["#309bf9", "#50d177", "#0fe2db", "#cc566e", "#febd25"];
const COLORS = [
    '#E7000B',
    '#FF8904',
    '#E17100',
    '#FDC700',
    '#5EA500',
    '#05DF72',
    '#009966',
    '#00D5BE',
    '#0092B8',
    '#00BCFF',
    '#155DFC',
    '#7C86FF',
    '#7F22FE',
    '#C27AFF',
    '#C800DE',
    '#FB64B6',
    '#EC003F',
    '#90A1B9',
    '#4A5565',
    '#9F9FA9',
    '#525252',
    '#A6A09B',
]

class IncomeProfitReport extends Component {
    constructor(props) {
        super(props);
        
        // Fechas por defecto: mismo día del mes anterior y fecha actual
        const defaultSince = moment().subtract(1, 'month').format('DD-MM-YYYY');
        const defaultUntil = moment().format('DD-MM-YYYY');

        this.state = {
            pieChartData: [],
            totalSales: 0,
            totalProfit: 0,
            form: {
                since: defaultSince,
                until: defaultUntil,
                user_id: this.props.user.role === 4
                    ? this.props.user.enterprise_users.enterprise_id
                    : this.props.user.id,
            },
            dates: {
                minDate: "",
                maxDate: ""
            },
        };
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders = async () => {
        try {
            let { form } = this.state;
            form.without_paginate = true;
            
            // Convertir fechas para la API si es necesario
            const params = {
                ...form,
                since: moment(form.since, "DD-MM-YYYY").format("YYYY-MM-DD"),
                until: moment(form.until, "DD-MM-YYYY").format("YYYY-MM-DD")
            };

            const response = await axios.post('admin/reports/orders', params);
            const orders = response.data.orders.filter(order =>
                order.status === Constants.ORDER_STATUS.PROCESSED && !order.deleted_at
            );

            // Filtrado por fechas
            const filteredOrders = orders.filter(order => {
                const orderDate = moment(order.created_at).format("YYYY-MM-DD");
                return (
                    orderDate >= params.since &&
                    orderDate <= params.until
                );
            });

            // Cálculos de ventas y ganancias
            const totalSales = filteredOrders.reduce((sum, order) => sum + order.total, 0);
            
            let totalProfit = filteredOrders.reduce((profit, order) => {
                const cost = order.details.reduce((sum, item) => {
                    const product = response.data.products.find(p => p.id === item.product_id);
                    let purchasePrice = product?.purchase_price || 0;

                    if (item.size_id && item.color_id) {
                        const sizeColor = product?.size_colors.find(sc => 
                            sc.size_id === item.size_id && 
                            sc.color_id === item.color_id
                        );
                        purchasePrice = sizeColor?.purchase_price || purchasePrice;
                    }
                    return sum + (purchasePrice * item.quantity);
                }, 0);
                return profit + (order.total - cost);
            }, 0);

            totalProfit = Math.max(totalProfit, 0);

            // Métodos de pago
            const paymentMethods = response.data.payment_methods
                .map((method, index) => {
                    const methodOrders = filteredOrders.filter(order =>
                        order.payment_methods.some(pm => pm.method_id === method.id)
                    );
                    const value = methodOrders.reduce((sum, order) => sum + order.total, 0);

                    return {
                        name: method.name,
                        value,
                        color: COLORS[index % COLORS.length]
                    };
                })
                .filter(item => item.value > 0);

            this.setState({
                totalSales,
                totalProfit,
                pieChartData: paymentMethods
            });

        } catch (error) {
            console.error("Error fetching orders", error);
        }
    };

    handleDateChange = (field, value) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [field]: moment(value).format("DD-MM-YYYY")
            }
        }), this.fetchOrders);
    };

    returnState = () => {
        // Restablecer a fechas por defecto
        const defaultSince = moment().subtract(1, 'month').format('DD-MM-YYYY');
        const defaultUntil = moment().format('DD-MM-YYYY');
        
        this.setState({
            form: {
                ...this.state.form,
                since: defaultSince,
                until: defaultUntil
            }
        }, this.fetchOrders);
    };

    render() {
        const { totalSales, totalProfit, pieChartData, form } = this.state;

        return (
            <Menu history={this.props.history}>
                <div id="dashboard">
                    {/* Filtros de Fecha */}
                    <div id="products" className="new-reports">
                        <div className="row">
                            <div className="col-md-2">
                                <DatePicker
                                    color="white"
                                    label="Desde"
                                    className="filter"
                                    value={moment(form.since, "DD-MM-YYYY").toDate()}
                                    onChange={value => this.handleDateChange("since", value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <DatePicker
                                    color="white"
                                    label="Hasta"
                                    className="filter"
                                    value={moment(form.until, "DD-MM-YYYY").toDate()}
                                    onChange={value => this.handleDateChange("until", value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="container-buttons">
                                    <Button className="btn-align-bottom btn-filter blue" onClick={this.fetchOrders}>
                                        <span>Filtrar</span>
                                    </Button>
                                    <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                                        <span>Limpiar</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Gráficas */}
                    <div style={{ 
                        display: "flex", 
                        flexDirection: "column", 
                        alignItems: "center",
                        gap: "40px",
                        padding: "20px 0"
                    }}>
                        <div style={{ width: "100%", maxWidth: "500px", textAlign: "center" }}>
                            <h4>Total ingresos y ganancias por ventas($)</h4>
                            <Pie
                                data={{
                                    labels: ["Ventas", "Ganancias"],
                                    datasets: [{
                                        data: [totalSales, totalProfit],
                                        backgroundColor: ["#50d177", "#309bf9"],
                                        borderWidth: 1
                                    }]
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: "right",
                                            labels: {
                                                usePointStyle: true,
                                                boxHeight: 15,
                                                boxWidth: 15,
                                                font: {
                                                    size: 14,
                                                },
                                            },
                                        },
                                        datalabels: {
                                            display: true,
                                            color: "white",
                                            font: { weight: "bold", size: 14 },
                                            formatter: (value) => `$${Globals.formatMiles(value)}`
                                        }
                                    }
                                }}
                            />
                        </div>

                        <div style={{ width: "100%", maxWidth: "500px", textAlign: "center" }}>
                            <h4>Monto de ventas según método de pago($)</h4>
                            <Pie
                                data={{
                                    labels: pieChartData.map(pm => pm.name),
                                    datasets: [{
                                        data: pieChartData.map(pm => pm.value),
                                        backgroundColor: pieChartData.map(pm => pm.color),
                                        borderWidth: 1
                                    }]
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: "right",
                                            labels: {
                                                usePointStyle: true,
                                                boxHeight: 15,
                                                boxWidth: 15,
                                                font: {
                                                    size: 14,
                                                },
                                                generateLabels: (chart) => {
                                                    const total = chart.data.datasets[0]?.data?.reduce((a, b) => a + b, 0);

                                                    return chart.data.labels.map((l, i) => {
                                                        const percentage = (chart.data.datasets[0]?.data[i] / total) * 100;
                                                        return {
                                                            datasetIndex: i,
                                                            text: `${percentage.toFixed(2)}% ${l}`,
                                                            fillStyle: chart.data.datasets[0].backgroundColor[i],
                                                            strokeStyle: chart.data.datasets[0].backgroundColor[i],
                                                            hidden: chart.getDatasetMeta(i).hidden,
                                                        }
                                                    });
                                                },
                                            },
                                        },
                                        datalabels: {
                                            display: false,
                                            color: "white",
                                            font: { weight: "bold", size: 14 },
                                            formatter: (value) => `$${value.toFixed(2)}`
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Menu>
        );
    }
}

export default connect(state => ({
    user: state.user
}))(IncomeProfitReport);