import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
    Table,
    Pagination,
    Button,
    Input,
    Select,
    CheckBox,
    Icon,
    DatePicker,
    Modal,
} from "../../components";
import { axios, Constants, Globals, Format, ENV } from "../../utils";
import { Branch, Warehouse, Category, Brand, Payment } from "../../services";
import NumberFormat from "react-number-format";
import moment from "moment";
import SearchIcon from '../../assets/icons/search.png';
import PrinterIcon from '../../assets/icons/printer.png';
import DownloadIcon from '../../assets/icons/download.png';
import PdfIcon from '../../assets/icons/pdf.png';



const HEADER_TABLE = [
    "ID\nPedido",
    "Fecha de\nPedido",
    "Fecha de\nPago",
    "Vendedor",
    "Cliente",
    "Total\nPedido",
    "Monto\nAbono",
    "Saldo",
    "Método de\nPago",
    "Estatus",
    "Información\nAdicional",
    "Acciones"
];

class CustomerPaymentReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trows: [],
            total: 0,
            page: 1,
            last_page: 1,
            data: [],
            paymentMethods: [],
            user: null,
            view: false,
            form: {
                status_payment: "",
                search: "",
                order_id: "",
                payment_date: "",
                order_date: "",
                method_id: "",
                brand_id: "",
                model_id: "",
                user_id: "",
            },
            user_id:
                this.props.user.role === 4
                    ? this.props.user.enterprise_users.enterprise_id
                    : this.props.user.id,
            role: this.props.user.role,
            dates: {
                minDate: "",
                maxDate: ""
            },
        };
    }

    abortController = new AbortController();

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    load = () => {
        this.getPayments();
        this.getPaymentMethods();
    };

    getPayments = (resetPage = null) => {
        let { page, form } = this.state;
        const { user } = this.props;

        if (typeof resetPage === "number") {
            page = resetPage;
        }

        form.user_id = user.id;
        form._paginate = true;

        axios
            .post("admin/reports/customerpaymentreport?page=" + page, form)
            .then(res => {
                this.setState({
                    last_page: res.data.payments.last_page,
                    data: res.data.payments.data,
                });
            })
            .catch(err => {
                Globals.showError();
            });
    };

    getPaymentMethods = async () => {
        const { form } = this.state;
        try {
            const res = await Payment.getPaymentMethods({ user_id: form.user_id, withoutPaginate: true });
            if (!res?.methods) throw new Error();
            this.setState({ paymentMethods: res.methods });

        } catch (error) {
            Globals.showError('Ocurrió un error al obtener los métodos de pago');
            console.log('getPaymentMethods -> catch: ', error)
        }
    };

    change = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    };

    returnState = async () => {
        await this.setState(state => ({
            ...state,
            search: '',
            form: {
                status_payment: "",
                search: "",
                order_id: "",
                payment_date: "",
                order_date: "",
                method_id: "",
                brand_id: "",
                model_id: "",
                user_id: "",
            },
            trows: [],
        }));
        await this.load();
    };

    print = (data) => {
        let { form } = this.state
        form.download = true
        axios
            .post("admin/reports/customerpaymentreport", this.state.form)
            .then(res => {
                if (res.data.url_storage) {
                    //const url = res.data.url;
                    const url = `${ENV.BasePublic}${res.data.url_storage}`;
                    const win = window.open(url, "_blank");
                    win.focus();
                }
                else {
                    Globals.showError('Ha ocurrido un error');
                }
            })
            .catch(err => {
                Globals.showError();
            });

    };

    isVoucherPDF = (file) => {
        return !!file ? String(file).endsWith('.pdf') : false;
    }

    downloadVoucher = (photo) => {
        let link = document.createElement('a');
        link.href = Globals.fromPhotos(photo);
        link.download = 'Comprobante';
        link.target = '_blank';
        link.click();
        link.remove();
    }

    render() {
        const { trows } = this.state;
        const showTotal = trows.find(i => i.value == 'total') && trows.find(i => i.value == 'cost');

        return (
            <Menu history={this.props.history}>

                <div id="products" className="new-reports">
                    <div className="row">
                        <div className="col-md-1">
                            <Input
                                color=" "
                                name="order_id"
                                label="ID"
                                className="filter"
                                onChange={this.change}
                                value={this.state.form.order_id}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') this.load();
                                }}
                            />
                        </div>                       
                        <div className="col-md-2">
                            <DatePicker
                                color="white"
                                label="Fecha de pedido"
                                className="filter"
                                minDate={this.state.dates.minDate}
                                value={this.state.form.order_date}
                                onChange={value => {
                                    this.setState(state => ({
                                        form: {
                                            ...state.form,
                                            order_date: value
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <DatePicker
                                color="white"
                                label="Fecha de pago"
                                className="filter"
                                maxDate={this.state.dates.maxDate}
                                value={this.state.form.payment_date}
                                onChange={value => {
                                    this.setState(state => ({
                                        form: {
                                            ...state.form,
                                            payment_date: value
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <Input
                                color=" "
                                name="search"
                                label="Búsqueda Cliente"
                                className="filter"
                                onChange={this.change}
                                value={this.state.form.search}
                                placeholder="RIF, Nombre"
                                onKeyDown={e => {
                                    if (e.key === 'Enter') this.load();
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select
                                color="white"
                                name="method_id"
                                label="Método de pago"
                                className="filter"
                                defaultname="Todos"
                                disabledFirst="false"
                                onChange={this.change}
                                value={this.state.form.method_id}
                                options={this.state.paymentMethods.map(Item => ({
                                    value: Item.id,
                                    label: Item.name
                                }))}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select
                                color="white"
                                name="status_payment"
                                label="Estatus"
                                className="filter"
                                defaultname="Todos"
                                disabledFirst={false} // Corregido: boolean sin comillas
                                onChange={this.change}
                                value={this.state.form.status_payment}
                                options={[
                                    { value: Constants.PAYMENT_TYPES.PAID, label: 'Pagado' },
                                    { value: Constants.PAYMENT_TYPES.TO_PAY, label: 'Por Pagar' },
                                ]}
                            />
                        </div>

                        <div className="col-md-4">
                            <div className="container-buttons">
                                <Button className="btn-align-bottom btn-filter blue" color=" " onClick={this.getPayments}>
                                    <span>Filtrar</span>
                                </Button>
                                <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                                    <span>Limpiar</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    
                    <Table
                        data={this.state.data.length}
                        title={'Reporte de Pagos de Clientes'}
                        header={HEADER_TABLE}
                        right={
                            <Button
                                title="Imprimir"
                                small="true"
                                onClick={() => this.print(this.state.data)}
                            >
                                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                            </Button>
                        }
                    >
                        {this.state.data.map((i, index) => {
                            return (
                                <tr key={index}>
                                    {/* Columnas alineadas a la IZQUIERDA */}
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.order?.correlative_number}</td>
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.order?.created_es}</td>
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.created_es}</td>
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.order?.seller?.name}</td>

                                    {/* Resto de columnas - Alineación original (derecha por defecto para números) */}
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.order?.client?.name}</td> {/* Cliente también a la izquierda */}
                                    <td style={{ maxWidth: 'none', textAlign: 'right' }}>
                                        {parseFloat(Number(i.order?.total || 0)).toFixed(2)}
                                    </td>
                                    <td style={{ maxWidth: 'none', textAlign: 'right' }}>
                                        {parseFloat(Number(i.amount || 0)).toFixed(2)}
                                    </td>
                                    <td style={{ maxWidth: 'none', textAlign: 'right' }}>
                                        {parseFloat(Number(i.to_pay_at_moment || 0)).toFixed(2)}
                                    </td>
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.method?.name}</td> {/* Método de pago a la izquierda */}
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.order?.status_payment_text}</td> {/* Estatus a la izquierda */}
                                    <td style={{ maxWidth: 'none', textAlign: 'left' }}>{i.comments ?? ''}</td> {/* Información adicional a la izquierda */}

                                    {/* Columna de acciones - Alineación original (centrada por defecto para botones) */}
                                    <td style={{ maxWidth: 'none' }}>
                                        {!!i?.photo && (
                                            <Button
                                                title="Descargar comprobante"
                                                small="true"
                                                color="primary"
                                                onClick={() => this.downloadVoucher(i?.photo)}
                                            >
                                                <Icon name="download" />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>

                    <Pagination
                        pages={this.state.last_page}
                        active={this.state.page}
                        onChange={async page => {
                            await this.setState({
                                page: page
                            });
                            this.load();
                        }}
                    />

                </div>
            </Menu>
        );
    }
}

export default connect(state => {
    return {
        user: state.user
    };
})(CustomerPaymentReport);
