import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseBlackIcon from '../../assets/icons/close-black.png';
import moment from 'moment';
import LocationIcon from '../../assets/icons/location.png';
import ENV from "../../utils/env";
import { Constants, Globals } from '../../utils';

// @ts-ignore
const google = window.google;

export class ModalViewVisitReport extends Component {

  state = {
    points: this.props.item?.details?.filter(item => item?.client?.latitude != null && item?.client?.longitude != null).map((item) => {
      return {
        ...item,
        latitude: parseFloat(item?.client?.latitude),
        longitude: parseFloat(item?.client?.longitude),
      }
    }),
    item: null,
    autosale: this.props.autosale ?? false,
    itemAutoSaleDetail: null,
    showPhoto: false,
  }

  componentDidMount() {
    this.setMap();
  }

  setMap = async () => {
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { 
        strokeColor: "#07a043",
        strokeWeight: 5
      }
    });

    if (this.state.points.length > 0) {
      const map = new google.maps.Map(document.getElementById('map-planner-view'), {
        zoom: 13,
        center: new google.maps.LatLng(this.state.points[0].latitude,this.state.points[0].longitude),
        draggable: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false
      });

      var waypoints = [];

      for (let i = 0; i < this.state.points.length; i++) {
        if (i != 0 && i != (this.state.points.length - 1)) {
          waypoints.push({
            location:  new google.maps.LatLng(this.state.points[i].latitude,this.state.points[i].longitude),
            stopover:true
          });
        }

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(this.state.points[i].latitude,this.state.points[i].longitude),
          map,
          title: '',
          icon: ENV.BasePublic + 'img/marker.png',
          animation: google.maps.Animation.DROP,
          draggable: false
        });

        google.maps.event.addListener(marker, 'click', () => {
          this.setState({
            item: this.state.points[i],
            itemAutoSaleDetail: this.state.autosale == true ? this.state.points[i] : null,
          });
        });
      }

      if (this.state.points.length > 1) {
        const request = {
            origin: new google.maps.LatLng(this.state.points[0].latitude,this.state.points[0].longitude),
            destination: new google.maps.LatLng(this.state.points[this.state.points.length - 1].latitude,this.state.points[this.state.points.length - 1].longitude),
            waypoints,
            optimizeWaypoints: true,
            travelMode: google.maps.DirectionsTravelMode.DRIVING
        };

        directionsDisplay.setMap(map);
        directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            }
        });
      }

      if (!!this.state.points.length) {
        this.setState({ item: this.state.points[0] });
      }
    }
  }

  render() {
    const item = this.props.item;
    console.log(item)
    const { itemAutoSaleDetail, showPhoto } = this.state;
    console.log('itemAutoSaleDetail: ', itemAutoSaleDetail)

    const keys = ['id'];
    const zones = [...item?.seller?.zones, item?.seller?.zone].filter(i => i !== null).filter((value, index, self) =>
      self.findIndex(v => keys.every(k => v[k] === value[k])) === index
    );

    return (
      <div className="modal-planer-view">
        {showPhoto && (
          <div className="modal-planner-view-photo">
            <div className="close-photo" onClick={() => this.setState({ showPhoto: false })}>
              <img src={CloseBlackIcon} alt="Cerrar" />
            </div>
            <div className="photo-wrapper">
              <img src={Globals.fromPhotos(this.state.item?.visit?.photo)} alt="" />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6">
            <div id="map-planner-view"></div>
          </div>
          <div className="col-md-6">
            <div className="text-right">
              <button className="btn-close" onClick={() => this.props.onClose()}>
                <img src={CloseBlackIcon} />
              </button>
            </div>
            <h3 className="seller">Fecha del recorrido: {item?.details[0].init_date ? moment(item?.details[0].init_date).format('DD-MM-YYYY') : '-'} </h3>
            <h3 className="seller">Vendedor: {item?.seller?.name}</h3>

            <br></br>
            <React.Fragment>
              {/* Iteración de detalles */}
              <table border={1} style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th>Cliente visitado</th>
                    <th>KM recorridos</th>
                    <th>Tiempo visita</th>
                  </tr>
                </thead>
                <tbody>
                  {item.details?.map((detail, index) => (
                    <tr key={index} style={{ textAlign: 'right' }}>
                      <td style={{ textAlign: 'center' }}>{detail.client?.name || '-'}</td>
                      <td style={{ textAlign: 'center' }}>{detail.km_traveled || '0 m'}</td>
                      <td style={{ textAlign: 'center' }}>{detail.working_time || '0 min'}</td>
                    </tr>
                  ))}

                  {/* Fila de totales */}
                  <tr style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    <td style={{ textAlign: 'center' }} colSpan="1">
                      Total:
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {Globals.calculateTotalDistance(item.details)}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {Globals.calculateTotalTime(item.details)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>

          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalViewVisitReport)