import React from 'react';
import { connect } from 'react-redux';
import Menu from '../menu';
import { Constants, Globals } from '../../utils';
import { PlanService } from '../../services';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';
import PaymentMethodIcon from '../../assets/icons/payment-method.png';
import PaymentDataIcon from '../../assets/icons/payment-data.png';
import PaymentSummaryIcon from '../../assets/icons/payment-summary.png';
import PaymentMobileIcon from '../../assets/icons/payment-mobile.png';
import PlanPaymentMobile from './plan-payment-mobile';

const STEP = {
  METHOD: 1,
  FORM: 2,
};

class PlanPayment extends React.Component {
  state = {
    step: STEP.METHOD,
    loading: true,
    form: {
      user_id: this.props.user.id,
      payment_method: null,
      amount: 0,
    },
    banks: [],
    phone_codes: [],
    methodInfo: null,
    plan: null,
		current_date: null,
		global_conversion: null,
		trial_expiration: null,
  }

  componentDidMount() {
    this.load();
  }

	load = async () => {
		const res = await PlanService.get({
			user_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
		});
		await this.setState({
			loading: false,
			plan: res.plan,
			current_date: res.current_date,
			global_conversion: res.global_conversion,
			trial_expiration: res.trial_expiration,
      methodInfo: {
        document: res.document,
        phone: res.phone,
        bank: res.bank,
      }
		});

    const amount = res.plan?.payment?.year ? (res.plan?.price_year * 12) : res.plan?.price;
    this.change(amount, 'amount');
	}

  change = async (value, target) => {
    await this.setState((state) => ({
      form: { ...state.form, [target]: value }
    }));
  }

  changeStep = (step) => {
    this.setState({ step });
  }

  goBack = () => {
    if (this.state.step === STEP.FORM)
      return this.changeStep(STEP.METHOD);
    this.props.history.replace('/profile-business');
  }

  selectMethod = async (method) => {
    if (!this.state.plan) return;
    await this.change(method, 'payment_method');
    this.changeStep(STEP.FORM);
  }

  onClose = () => {
    this.props.history.replace('/profile-business');
  }

  render() {

    const { form, loading, plan, step } = this.state;

    return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={this.goBack} />}
      >
        <main id="profile-business-payment">
          <nav>
            <div className="step">
              <div className="step-icon active">
                <img src={PaymentMethodIcon} alt="Step 1" />
              </div>
              <p>Método de pago</p>
            </div>

            <div className="divider">
              <div className={`line ${this.state.step === STEP.FORM ? 'active' : ''}`} />
              <p>void</p>
            </div>

            <div className="step">
              <div className={`step-icon ${this.state.step === STEP.FORM ? 'active' : ''}`}>
                <img src={PaymentDataIcon} alt="Step 2" />
              </div>
              <p>Datos de pago</p>
            </div>
          </nav>

          {step === STEP.METHOD && (
            <section className="method-step">
              <div className="summary">
                <div className="summary-icon">
                  <img src={PaymentSummaryIcon} alt="Método de pago" />
                </div>
                <div className="summary-details">
                  {loading ? (
                    <>
                      <div className="loading-text skeleton" style={{ width: '7rem' }} />
                      <div className="loading-text skeleton" style={{ width: '15rem' }} />
                    </>
                  ) : (
                    <>
                      <p>${ Globals.formatMiles(form.amount) }</p>
                      <p>{ plan?.name } ({ plan?.payment?.year ? 'Anual' : 'Mensual' })</p>
                    </>
                  )}
                </div>
              </div>

              <div className="method-option" onClick={() => this.selectMethod(Constants.PLAN_PAYMENT_METHODS.MOVIL)}>
                <div className="method-option-icon">
                  <img src={PaymentMobileIcon} alt="Pago móvil" />
                </div>
                <div className="method-option-details">
                  <p>Pago móvil</p>
                </div>
              </div>

              {/* <div className="method-option" onClick={() => this.selectMethod(Constants.PLAN_PAYMENT_METHODS.TRANSFER)}>
                <div className="method-option-icon">
                  <img src={PaymentTransferIcon} alt="Transferencia" />
                </div>
                <div className="method-option-details">
                  <p>Transferencia</p>
                </div>
              </div> */}
            </section>
          )}

          {step === STEP.FORM && (
            <section className="form-step">
              {form.payment_method === Constants.PLAN_PAYMENT_METHODS.MOVIL && (
                <PlanPaymentMobile
                  onGoBack={this.goBack}
                  onClose={this.onClose}
                  plan={plan}
                  user={this.props.user}
                  amount={form.amount}
                  conversion={this.state.global_conversion.amount}
                  methodInfo={this.state.methodInfo}
                />
              )}
            </section>
          )}
        </main>
      </Menu>
    );
  }
}

const BackButton = ({ onClick }) => (
  <div className="back-button">
    <img src={ LeftArrowIcon } onClick={() => onClick()} alt="Regresar" />
  </div>
)

export default connect(state => {
  return {
    user: state.user,
  };
})(PlanPayment);
