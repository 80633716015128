import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Modal
} from "../../components";
import { axios, Constants, Globals } from "../../utils";
import LocationPinIcon from '../../assets/icons/location-pin.png';
import AddIcon from '../../assets/icons/add.png';
import moment from 'moment';
import ModalViewVisitReport from './modal-view-visitReport';
import ModalViewRoute from './modal-route';

const INITIAL_FORM = {
  since: "",
  until: "",
  search: "",
  status: "",
  type: Constants.VISIT_PLANNER_TYPE.UNPLANNED,
}

class VisitRouteTraveledReport extends React.Component {

  state = {
    header: [
      'Fecha',
      'Vendedor',
      'KM\nRecorridos',
      'Tiempo de\nTrabajo',
      'Zonas Asignadas',
      'Tipo de Ruta',
      'Ruta',
      'Acciones'
    ],
    page: 1,
    last_page: 1,
    data: [],
    form: INITIAL_FORM,
    user_id: this.props.user.level_id === Constants.ROLE_ADMIN
      ? this.props.user.id
      : this.props.user.admin_id,
    visible: false,
    item: null,
    visible_route: false
  };

  componentDidMount() {
    this.fetchData();
  };

  fetchData = (page = 1) => {
    let { form } = this.state;

    form.user_id = this.props.user.id;    

    axios
      .post("admin/visit-planners/route-traveled-report?page=" + page, {
        ...form,
        since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
        until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      })
      .then(res => {
        this.setState({
          page,
          last_page: res.data.visit_planners.last_page,
          data: res.data.visit_planners.data
        });

        if (res.data.admin?.admin_functionalities?.map((i) => i.id).indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) == -1) {
          this.props.history.replace('/');
        }
      })
      .catch(() => {
        Globals.showError();
      });
  };

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  };

  cleanFilters = async () => {
    this.setState({ form: INITIAL_FORM });
  }

  view = (item) => {
    this.setState({
      visible: true,
      item
    });
  }

  edit = (id) => {
    this.props.history.push(`visit-planner/create/${id}`);
  }

  delete = (id) => {
    Globals.confirm('¿Desea eliminar esta ruta?', () => {
      Globals.setLoading();
      axios.post('admin/visit-planners/delete', { id, user_id: this.props.user.id })
      .then(res => {
        Globals.showSuccess(`Ruta eliminada con exito!`);
        this.fetchData();
      })
      .catch(() => Globals.showError())
      .finally(() => Globals.quitLoading())
    })
  }

  viewRoute = (item) => {
    this.setState({
      visible_route: true,
      item
    });
  }

  close = () => {
    this.setState({
      visible: false,
      item: null
    });
  }

  closeRoute = () => {
    this.setState({
      visible_route: false,
      item: null
    });
  }

  render() {
    const { search, status, since, until } = this.state.form;
    const { visible, visible_route } = this.state;

    return (
      <Menu history={this.props.history}>
        {
          visible && (
            <Modal
              className="modal-planner-view"
              title=""
              onClose={this.close}
              visible
            >
              <ModalViewVisitReport
                item={ this.state.item }
                onClose={this.close}
              />
            </Modal>
          )
        }

        {
          visible_route && (
            <Modal
              className="modal-planner-view-route"
              title=""
              onClose={this.closeRoute}
              visible
            >
              <ModalViewRoute
                item={ this.state.item }
                onClose={this.closeRoute}
              />
            </Modal>
          )
        }

        <div id="visit-planner">
          <div className="row">
            <div className="col-2">
              <DatePicker
                color="white"
                label="Desde"
                maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
                className="filter"
                labelClass="filter"
                value={this.state.form.since}
                onChange={value => this.change(value, 'since')}
              />
            </div>
            <div className="col-2">
              <DatePicker
                color="white"
                label="Hasta"
                minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : null }
                className="filter"
                value={this.state.form.until}
                onChange={value => this.change(value, 'until')}
              />
            </div>
            <div className="col-2">
              <Input
                color=" "
                label="Vendedor"
                placeholder="Escribir"
                className="filter"
                labelClass="filter"
                value={this.state.form.search }
                onChange={e => this.change(e.target.value, 'search')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Tipo"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={[
                  { value: Constants.VISIT_PLANNER_TYPE.PLANNED, label: 'Planificadas', status: 0 },
                  { value: Constants.VISIT_PLANNER_TYPE.UNPLANNED, label: 'No Planificadas' },
                ]}
                value={this.state.form.type}
                onChange={e => this.change(e.target.value, 'type')}
              />
            </div>

            <div className="col-4 row">
              <Button
                className="btn-align-bottom btn-filter mr-3"
                color=" "
                onClick={() => this.fetchData()}
              >
                <span>Filtrar</span>
              </Button>

              {
                (since != '' || until != '' || search != '' || status != '') && (
                  <Button
                    className="btn-align-bottom btn-filter"
                    color=" "
                    //onClick={this.cleanFilters}
                    onClick={async () => {
                      await this.cleanFilters();
                      this.fetchData();
                   }}
                  >
                    <span>Limpiar</span>
                  </Button>
                )
              }
            </div>
          </div>

          <Table
            title="Reporte Recorrido de Rutas"
            data={this.state.data.length}
            header={this.state.header}
						right={
              <Link to="visit-planner/create">
                <img src={AddIcon} width={32} alt="Agregar" />
              </Link>
						}
          >
            {this.state.data.map((i, index) => {
              const keys = ['id'];
              const zones = [...i.seller?.zones,i.seller?.zone].filter(i => i !== null).filter((value, index, self) =>
                self.findIndex(v => keys.every(k => v[k] === value[k])) === index
              );
              const isAfter = moment(i.date).isSameOrAfter(moment(moment().format('YYYY-MM-DD 00:00:00')));
              const isPending = i.status === Constants.VISIT_PLANNER_STATUS.PENDING;

              return (
                <tr key={index}>
                  <td>{ moment(i.date).format('DD-MM-YYYY') }</td>
                  <td>{ i.seller?.name }</td>
                  <td>{ Globals.calculateTotalDistance(i.details) }</td>
                  <td>{ Globals.calculateTotalTime(i.details) }</td>
                  <td>{ zones.map(i => i.name).filter(i => i != null).join(', ') }</td>
                  <td>{ i?.type_text }</td>
                  <td>
                      <img
                        src={LocationPinIcon}
                        className="location-pin"
                        alt="Ubicación"
                        onClick={() => this.viewRoute(i)}
                      />
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => this.view(i)}
                    >
                      <Icon name="eye" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => this.fetchData(page)}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(VisitRouteTraveledReport);